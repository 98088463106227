import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export const ConsultInfo = () => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: '0px', md: '10px 20px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '60% 40%' },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '0px', md: '30px' },
        }}
      >
        <iframe
          className="iFrame-watch-video"
          width="100%"
          height="500px"
          src="https://www.youtube.com/embed/3sIVRTKUWCA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Box>
      <Box style={{ marginLeft: '20px', overflow: 'auto', height: '550px' }}>
        <Typography
          style={{ marginTop: '25px', fontWeight: 'bold' }}
          gutterBottom
          variant="h5"
          component="div"
        >
          WATCH THE VIDEO!
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{ marginTop: '10px', fontWeight: 'bold' }}
        >
          THIS VIDEO IS IMPORTANT FOR KNOWING HOW TO COME PREPARED FOR YOUR CALL
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          REQUIREMENTS RECAP:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          1. This meeting will be on zoom.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          2. Your zoom link can be found in your email.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          3. For Doubles--make sure the other person takes their Neurotoxicity
          Assessment!
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          4. If you need to reschedule before 4 days (96 hours) use the link in
          that same email that was sent to you regarding your appointment! (You
          can do this 1x free of charge.)
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          5. If you need to reschedule within 96 hours reach out to your Point
          of Contact and they can reschedule you for a $37 reinstatement fee
          (this time slot is 100% dedicated to you.)
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          If you're married (or have someone else that's a part of your support
          system) they need to be there as well!{' '}
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          What You Should Come Ready With:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          1. Neurotoxicity Assessment Completed
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          2. Meta-Oxy Home Test Results
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          3. Know Where to Find Your Zoom Link!
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          4. Be in a Quiet Place Ready to Dive in to What's Been Going On
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          5. If you're married (or have someone else that's a part of your
          support system) they need to be there as well!
        </Typography>
      </Box>
    </Box>
  );
};
