import React, { useEffect } from 'react';
import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds';

const WatchVideo = ({ videoId, videoStyle }) => {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;

    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, [videoId]);

  return (
    <WistiaProvider>
      <WistiaPlayer hashedId={videoId} videoFoam={false} style={videoStyle} />
    </WistiaProvider>
  );
};

export default WatchVideo;
