import React, { useEffect } from 'react';
import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds';

const NewWatchFirstVideo = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    script1.src = 'https://fast.wistia.com/embed/medias/rbnzulxqer.jsonp';
    script1.async = true;
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;
    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, []);

  return (
    <WistiaProvider>
      <WistiaPlayer
        hashedId="rbnzulxqer"
        videoFoam={false}
        style={{
          width: '1030px',
          height: '580px',
          marginBottom: '60px',
          marginTop: '20px',
        }}
      />
    </WistiaProvider>
  );
};

export default NewWatchFirstVideo;
