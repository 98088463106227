import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';
import StepsLayout from '../../app/common/StepsLayout';
import { useNavigate } from 'react-router-dom';
import ChildProfileForm from './child-profile-form';
import {
  getProduct,
  getUserData,
  setStage,
} from '../../app/services/AuthService';

const ChildProfile = () => {
  const initialChildData = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const [childrenData, setChildrenData] = useState([initialChildData]);
  const navigate = useNavigate();
  const userData = getUserData();
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');

  function validateChildrenData() {
    for (const child of childrenData) {
      if (
        child.firstName.trim() === '' ||
        child.lastName.trim() === '' ||
        child.email.trim() === '' ||
        child.email === userData.email
      ) {
        return false;
      }
    }
    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (childrenData.length === 0) {
      setMessageType('error');
      setErrorMessage('You need to add at least one family member.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }
    if (validateChildrenData() === false) {
      setMessageType('error');
      setErrorMessage('You need to fill all the fields.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }
    setErrorMessage(null);
    setUpdating(true);
    const config = {
      url: getUrl() + '/multiple-child',
      method: 'post',
      data: {
        children: childrenData,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    let stage = 0;
    if (getProduct() === 'single' || getProduct() === 'double') {
      stage = 3;
    } else {
      stage = 6;
    }
    axios(config)
      .then(() => {
        setStage(stage);
        setUpdating(false);
        setErrorMessage(
          'Success Inviting our Family. Redirecting to Next Step...'
        );
        setMessageType('success');
        setTimeout(() => {
          if (getProduct() === 'single' || getProduct() === 'double') {
            navigate('/profile');
          } else {
            navigate('/');
          }
        }, 2000);
      })
      .catch((error) => {
        setMessageType('error');
        setUpdating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  function addNewDefaultChild() {
    setChildrenData([
      ...childrenData,
      {
        firstName: '',
        lastName: '',
        email: '',
      },
    ]);
  }

  function updateChild(index, child) {
    setChildrenData((prevData) => {
      const newData = [...prevData];
      newData[index] = child;
      return newData;
    });
  }

  function removeLastChild() {
    if (childrenData.length > 1) {
      setChildrenData(childrenData.slice(0, -1));
    }
  }

  return (
    <StepsLayout>
      <Box display="flex">
        {getProduct() === 'double' ? (
          <img src="/1-icon.png" alt="Logo" style={{ height: '84px' }} />
        ) : (
          <img src="/5-icon.png" alt="Logo" style={{ height: '84px' }} />
        )}
        <Typography component="h1" variant="h3" className="steps-header">
          Invite Others
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ backgroundColor: 'white', mt: 3 }}
      >
        <Typography
          component="h3"
          variant="h3"
          sx={{
            fontSize: '21px !important',
            fontWeight: 'bold',
            color: '#3e4959',
            padding: '25px',
            paddingBottom: '0px !important',
            textAlign: 'center',
          }}
        >
          We will need anyone who is taking an inflammation test kit to fill out
          the same quick form you just did!
        </Typography>
        {childrenData.map((child, index) => (
          <ChildProfileForm
            key={index}
            firstName={child.firstName}
            lastName={child.lastName}
            email={child.email}
            updateChild={(newChild) => updateChild(index, newChild)}
          />
        ))}
        <Button
          disabled={updating}
          type="submit"
          fullWidth
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          SEND INVITE
        </Button>
        <Button
          onClick={addNewDefaultChild}
          type="button"
          fullWidth
          style={{
            float: 'right',
            width: '50px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#1b2029',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2, mr: 2 }}
        >
          +
        </Button>
        <Button
          onClick={removeLastChild}
          type="button"
          disabled={childrenData.length === 1}
          fullWidth
          style={{
            float: 'right',
            width: '50px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: childrenData.length === 1 ? '#b0b0b0' : '#1b2029',
            cursor: childrenData.length === 1 ? 'not-allowed' : 'pointer',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2, mr: 2 }}
        >
          -
        </Button>
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
      </div>
    </StepsLayout>
  );
};

export default ChildProfile;
