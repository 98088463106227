import React from 'react';
import Typography from '@mui/material/Typography';
import FormsLayout from '../../app/common/FormsLayout';
import { getStage } from '../../app/services/AuthService';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import BookNewCall from '../book-new-call/book-new-call';

const BookCall = () => {
  const stage = getStage();
  const navigate = useNavigate();
  const [showEmbed] = React.useState(stage > 1);
  const homeHandler = () => {
    navigate('/');
  };

  if (showEmbed) {
    return (
      <FormsLayout padding={'PADDING'}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontWeight: 'bolder',
            fontFamily: "'Open Sans', sans-serif",
            color: 'black',
            fontSize: '1.9rem',
            marginBottom: '30px',
            letterSpacing: '-2.5px',
          }}
        >
          You have already scheduled your analysis call.
        </Typography>
        <Typography component="p">
          To reschedule your call, please email us at{' '}
          <a href="mailto:info@program.com">info@program.com</a> Or Call{' '}
          <a href="tel:800-691-7422">(800) 691-7422</a>
        </Typography>
        <Button
          style={{
            width: '200px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          onClick={homeHandler}
          type="button"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          HOME
        </Button>
      </FormsLayout>
    );
  }

  return <BookNewCall />;
};

export default BookCall;
