import React from 'react';

export default function DarkLogo() {
  return (
    <div
      style={{
        display: 'flex',
        margin: '10px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src="/dark_logo.png"
        alt="Pompa Program"
        style={{ height: '40px' }}
      />
    </div>
  );
}
