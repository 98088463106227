import React, { useEffect, useState } from 'react';
import StepsLayout from '../../app/common/StepsLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { getToken, getUser, getUserData } from '../../app/services/AuthService';
import BasicCard from './Card-item';
import { getApiUrl, getUrl } from '../../app/api/agent';

export default function AssessmentsHome() {
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const url = getApiUrl();

  useEffect(() => {
    const fetchAssessments = async () => {
      const userData = getUserData();
      let userId = userData.userId;

      if (!userId) {
        try {
          const token = getToken();

          const verifyResponse = await axios.post(getUrl() + '/verify', {
            email: getUser(),
            token: token,
          });
          userId = verifyResponse.data.userId;
        } catch (error) {
          console.error('Error verify:', error);
        }
      }

      try {
        const response = await axios.post(`${url}/assessments`, {
          action: 'getAssignedAssessments',
          userId: userId,
        });

        setAssessments(response.data.response);
      } catch (error) {
        console.error('Error fetching assessments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, []);

  if (loading) {
    return (
      <StepsLayout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </StepsLayout>
    );
  }

  const renderCards = () => {
    return assessments.map((assessment) => (
      <BasicCard key={assessment.id} assessment={assessment} />
    ));
  };

  return (
    <StepsLayout>
      <div style={{ marginTop: '20px' }}>
        <Box id="content-questions" component="form" noValidate>
          <Box
            sx={{ backgroundColor: '#f7fbfd', margin: '7px', padding: '20px' }}
          >
            <Box sx={{ pt: 3, pl: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  pt: 2,
                  pb: 2,
                  fontWeight: 'bolder',
                  color: '#536d7b',
                  fontSize: { xs: '16px', md: '30px' },
                }}
              >
                Assessments
              </Typography>
            </Box>
            <hr />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              {renderCards()}
            </Box>
          </Box>
        </Box>
      </div>
    </StepsLayout>
  );
}
