import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import VideoEmbed from '../book-new-call/VideoEmbed';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import { getApiKey, getUrl } from '../../app/api/agent';

const WhichAppointment = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const [bookings, setBookings] = useState([]);
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const decodedOrderId = decodeURIComponent(orderId);
  const decodedEmail = decodeURIComponent(searchParams.get('email'))
    ? decodeURIComponent(searchParams.get('Email'))
    : '';

  useEffect(() => {
    document.body.style.backgroundColor = 'white';

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.pompaprogram.com/get-samcart-customer?order_id=${decodedOrderId}`
        );
        const { first_name, last_name, email, phone } = response.data.customer;
        setCustomerData({
          firstName: first_name,
          lastName: last_name,
          email,
          phone,
        });
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    fetchData();
    hasBookedAppointment();

    return () => {
      document.body.style.backgroundColor = '#e0f2fe';
    };
  }, []);

  const buildQueryString = (params) => {
    return Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== '') // Filter out null or empty values
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      ) // Encode and join
      .join('&'); // Join all parameters with '&'
  };
  const goToSingle = () => {
    const params = {
      Name: customerData.firstName,
      lastName: customerData.lastName,
      mobile: customerData.phone,
      email: customerData.email,
    };
    const queryString = buildQueryString(params);
    window.location.href = `/time2book?${queryString}`;
  };

  const goToDouble = () => {
    const params = {
      Name: customerData.firstName,
      lastName: customerData.lastName,
      mobile: customerData.phone,
      email: customerData.email,
    };
    const queryString = buildQueryString(params);
    window.location.href = `/time2book2?${queryString}`;
  };

  const hasBookedAppointment = async () => {
    try {
      console.log('searching for booking');
      const response = await axios.get(
        `https://api.pompaprogram.com/has-booked?email=${decodedEmail}`
      );
      setBookings(response.data.bookings);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const sendMagicLink = async () => {
    try {
      await axios({
        url: getUrl() + '/magic-link',
        method: 'post',
        data: {
          email: decodedEmail,
          redirectTo: 'profile',
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      });
      setMagicLinkSent(true);
    } catch (error) {
      console.log('Error sending magic link', error);
    }
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="55%" message="Almost Complete..." />
        </Typography>
      </Box>
      <Box
        sx={{
          fontSize: '32px',
          fontWeight: '700',
          textAlign: 'center',
          marginTop: '30px',
          marginBottom: '20px',
          '@media (max-width: 700px)': {
            fontSize: '22px',
          },
        }}
      >
        {' '}
        Now you need to book your cellular analysis appointment.
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <VideoEmbed />
        <Box mb={2} />
      </div>
      {bookings && bookings.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              sx={{
                fontSize: '26px',
                textAlign: 'center',
                fontWeight: 'bold',
                margin: 'auto',
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '28px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                  gutterBottom
                >
                  Wait! It looks like you have already booked an appointment.
                </Typography>
              </Box>
              <Box>
                {bookings.map((booking) => (
                  <Box key={booking.id} mb={2}>
                    <Typography variant="h6">{booking.subject}</Typography>
                    <Typography variant="body1">
                      <strong>Date and Time:</strong>
                      {new Date(booking.starting_time).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {booking.form_submission.email}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  An email will be sent to {decodedEmail || ''} with a login
                  link
                </Typography>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMagicLink();
                  }}
                  style={{
                    color: 'blue',
                    fontSize: '18px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Send Magic Link
                </a>
                {magicLinkSent && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '18px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: 'green',
                    }}
                  >
                    Magic Link sent to {decodedEmail || ''}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: '24px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginBottom: '20px',
                }}
                gutterBottom
              >
                If you'd still like to book a call, please click one of the
                buttons below:
              </Typography>
            </Typography>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <Button
          sx={{
            mb: 2,
            width: '307px',
            borderRadius: '3px',
            backgroundColor: '#e7313d',
            fontSize: '17px',
            boxShadow: 'inset 0 1px 0 rgba(255,255,255,0.2)',
            border: '1px solid rgba(0,0,0,0.2)',
            textTransform: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
            '&:hover': {
              backgroundColor: '#e7313d',
              boxShadow:
                'inset 0 2px 2px 0 #ffffff38, 0 233px 233px 0 rgba(255,255,255,0.12) inset',
            },
          }}
          onClick={goToSingle}
          type="button"
          variant="contained"
        >
          <Typography
            variant="body1"
            component="span"
            style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}
          >
            Single Appointment
          </Typography>
          <Typography
            variant="caption"
            component="span"
            style={{ color: 'white', fontSize: '14px', opacity: '0.7' }}
          >
            For those that purchased a single test kit
          </Typography>
        </Button>

        <Button
          sx={{
            mb: 2,
            width: '307px',
            borderRadius: '3px',
            backgroundColor: '#e7313d',
            fontSize: '17px',
            boxShadow: 'inset 0 1px 0 rgba(255,255,255,0.2)',
            border: '1px solid rgba(0,0,0,0.2)',
            textTransform: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
            '&:hover': {
              backgroundColor: '#e7313d',
              boxShadow:
                'inset 0 2px 2px 0 #ffffff38, 0 233px 233px 0 rgba(255,255,255,0.12) inset',
            },
          }}
          onClick={goToDouble}
          type="button"
          variant="contained"
        >
          <Typography
            variant="body1"
            component="span"
            style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}
          >
            Double Appointment
          </Typography>
          <Typography
            variant="caption"
            component="span"
            style={{ color: 'white', fontSize: '14px', opacity: '0.7' }}
          >
            For those that purchased two test kits
          </Typography>
        </Button>
      </Box>
    </BookStepsLayout>
  );
};

export default WhichAppointment;
