import React from 'react';

export default function Logo() {
  return (
    <div
      style={{
        display: 'flex',
        margin: '10px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src="/logo.png" alt="Logo" style={{ height: '40px' }} />
    </div>
  );
}
