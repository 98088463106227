import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';
import { Alert, MenuItem, Select } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AdminUserTable from './admin-user-table';

export default function AdminUserHistory() {
  const [dateRange, setDateRange] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [users, setUsers] = useState(null);
  const [searching, setSearching] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setUsers(null);
    setSearching(true);

    let startDate;
    let endDate;
    const now = new Date();

    switch (dateRange) {
      case '24':
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        endDate = now;
        break;
      case '48':
        startDate = new Date(now.getTime() - 48 * 60 * 60 * 1000);
        endDate = now;
        break;
      case '72':
        startDate = new Date(now.getTime() - 72 * 60 * 60 * 1000);
        endDate = now;
        break;
      default:
        startDate = new Date(0);
        endDate = now;
        break;
    }

    const config = {
      url: getUrl() + '/report',
      method: 'post',
      data: {
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
        stage: 1,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUsers(response.data.data.Items);
        setSearching(false);
      })
      .catch(() => {
        setSearching(false);
        setUsers(null);
        setMessageType('error');
        setErrorMessage(
          'There has been a problem with the request, please try again later'
        );
      });
  };

  return (
    <>
      <Box component="form" display="grid" noValidate onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: 'white',
            padding: '50px 30px 30px 30px',
            borderRadius: '10px',
          }}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Date Filter
              </Typography>
              <Select
                value={dateRange}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={'true'}
                onChange={(event) => setDateRange(event.target.value)}
              >
                <MenuItem value="24">24</MenuItem>
                <MenuItem value="48">48</MenuItem>
                <MenuItem value="72">72</MenuItem>
                <MenuItem value="more">More</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          fullWidth
          disabled={searching}
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Find Users
        </Button>
        <AdminUserTable users={users} />
        <div style={{ marginTop: '10px', maxWidth: '400px' }}>
          {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
        </div>
      </Box>
      {searching && <CircularProgress />}
    </>
  );
}
