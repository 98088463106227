import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Loader from '../loader/Loader';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import { renderToString } from 'react-dom/server';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Create30DCR() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [missingParameters, setMissingParameters] = useState(false);
  const [inputName, setInputName] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState('mock');

  useEffect(() => {
    const emailParam = searchParams.get('Email');
    const nameParam = searchParams.get('Name');

    if (emailParam && nameParam) {
      setEmail(emailParam.toLowerCase());
      setName(nameParam);
    } else {
      setStatus('missing parameters');
      setMissingParameters(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (email && name && !password) {
      autoregister();
    }
  }, [email, name]);

  const sendEmail = (token) => {
    const emailContent = renderToString(htmlTemplate(token));
    const config = {
      url: 'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod/email',
      method: 'post',
      data: {
        email: email,
        content: emailContent,
        subject:
          '[LOGIN INSIDE] Your Neurotoxicity Assessment is Ready to Start',
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const htmlTemplate = (token) => {
    return (
      <html>
        <body>
          <p>{name.split(' ')[0]},</p>
          <p>
            Your Neurotoxicity Assessment account has been created and we're
            ready for you to start your assessment.
          </p>
          <p>
            This assessment will help identify your upstream stressors and help
            put together a plan that will put you on a path back to wellness as
            fast as possible.
          </p>
          <p>
            <a
              href={`https://apply.pompaprogram.com/reset?token=${token}&email=${email}`}
            >
              Click here to set your password and start the assessment now.
            </a>
          </p>
          <p>Thanks,</p>
          <p>The Pompa Program Team</p>
        </body>
      </html>
    );
  };

  const autoregister = () => {
    const config = {
      url: getUrl() + '/autoregisteroncehub',
      method: 'post',
      data: {
        email: email,
        name: name,
        type: '30dcr',
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setPassword(response.data.password);

        login(response.data.password, true);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 409) {
          setErrorMessage('User already exist, please login or reset password');
          //redirect to home after 2 seconds
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const login = (loginPassword, sendEmailFlag) => {
    const config = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: loginPassword,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        if (sendEmailFlag) {
          sendEmail(response.data.token);
        }
        setTimeout(() => {
          navigate('/profile');
        }, 10000);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    if (inputName.trim() === '' || email.trim() === '') {
      setErrorMessage('Both name and email are required');
      return;
    }
    setStatus('mock');
    setName(inputName);

    await autoregister();
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            marginBottom: 2,
          }}
          component="h1"
          variant="h5"
        >
          Well Done, {name.split(' ')[0]}!
        </Typography>
        <Typography
          sx={{
            fontWeight: 'normal',
            fontSize: '1rem',
            textTransform: 'uppercase',
          }}
          component="h3"
          variant="h5"
        >
          Please do not close or refresh your web browser.
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            marginTop: 2,
          }}
          component="h2"
          variant="h5"
        >
          We're preparing your Neurotoxicity Questionnaire now.
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
          component="h2"
          variant="h5"
        >
          Please complete the next steps as soon as possible.
        </Typography>
        <Loader status={status} />
      </Box>
      {missingParameters && (
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <Typography
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            component="h3"
            variant="h5"
          >
            please enter your information
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            value={inputName}
            onChange={(event) => setInputName(event.target.value)}
            name="name"
            label="Name"
            type="name"
            id="name"
            autoComplete="current-name"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            name="email"
            label="Email"
            type="email"
            id="email"
            autoComplete="Email"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      )}

      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
