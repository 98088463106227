import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, CircularProgress } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {
  getMatchedSum,
  getNtqPercentage,
  getQuestion,
  getQuestions,
  loadQuestionsToSessionStorage,
  setNtqPercentage,
  validateQuestionnaireSection,
  validateQuestionsBySection,
} from '../../app/services/QuestionsService';
import {
  getProduct,
  getUserData,
  getUserLeadScoreId,
  getUserNTQ,
  getUserSessionEmail,
  setStage,
  setUserLeadScore,
  setUserNTQ,
} from '../../app/services/AuthService';
import { createConfigRequest } from '../../app/api/agent';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StepsLayout from '../../app/common/StepsLayout';
import './Forms.css';
import moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';
import MajorComplaints from './steps/MajorComplaints';
import Supplements from './steps/Supplements';
import DietHistory from './steps/DietHistory';
import ClientHistory from './steps/ClientHistory';
import Mercury from './steps/Mercury';
import Cavitation_HiddenInfection from './steps/Cavitation_HiddenInfection';
import Aluminum from './steps/Aluminum';
import Lead from './steps/Lead';
import GeneralToxicity from './steps/GeneralToxicity';
import HomeEnvironment from './steps/HomeEnvironment';
import Mold from './steps/Mold';
import Lyme from './steps/Lyme';
import HealthHistory from './steps/HealthHistory';
import Covid from './steps/Covid';
import Emf from './steps/Emf';
import MicrobiomeHealth from './steps/MicrobiomeHealth';
import Parasites from './steps/Parasites';
import Sibo from './steps/Sibo';
import Bacterial_Viral from './steps/Bacterial_Viral';
import Emotional from './steps/Emotional';
import Questionare from './steps/Questionare';

export default function NewForms() {
  const questionareRef = useRef();
  const [activeStep, setActiveStep] = React.useState(0);
  const [messageType, setMessageType] = useState('success');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [sending, setSending] = React.useState(true);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [width, setWidth] = useState(window.innerWidth);
  const [showForm, setShowForm] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showWarningText, setShowWarningText] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [filled, setFilled] = useState(!!getUserNTQ());
  const [isLastPage, setIsLastPage] = useState(false);
  let steps = [
    'Questionnaire',
    'Major Complaints',
    'Supplements',
    'Diet History',
    'Health History',
    'Health History',
    'Mercury',
    'Cavitation/Hidden Infection',
    'Aluminum',
    'Lead',
    'General Toxicity',
    'Home Environment',
    'Mold',
    'Lyme',
    'Covid',
    'EMF',
    'Microbiome Health',
    'Parasites',
    'SIBO',
    'Other Pathogens',
    'Emotional',
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Questionare
            onIsLastPageChange={handleIsLastPageChange}
            ref={questionareRef}
            setErrorMessage={setErrorMessage}
            setMessageType={setMessageType}
          />
        );
      case 1:
        return <MajorComplaints />;
      case 2:
        return <Supplements />;
      case 3:
        return <DietHistory />;
      case 4:
        return <ClientHistory />;
      case 5:
        return <HealthHistory />;
      case 6:
        return <Mercury />;
      case 7:
        // eslint-disable-next-line react/jsx-pascal-case
        return <Cavitation_HiddenInfection />;
      case 8:
        return <Aluminum />;
      case 9:
        return <Lead />;
      case 10:
        return <GeneralToxicity />;
      case 11:
        return <HomeEnvironment />;
      case 12:
        return <Mold />;
      case 13:
        return <Lyme />;
      case 14:
        return <Covid />;
      case 15:
        return <Emf />;
      case 16:
        return <MicrobiomeHealth />;
      case 17:
        return <Parasites />;
      case 18:
        return <Sibo />;
      case 19:
        // eslint-disable-next-line react/jsx-pascal-case
        return <Bacterial_Viral />;
      case 20:
        return <Emotional />;
      default:
        throw new Error('Unknown step');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadQuestionsToSessionStorage();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().finally(() => {
      setShowForm(true);
    });

    window.addEventListener('userSawLastQuestion', activateNextButton);
    window.addEventListener('userIsNotLastQuestion', userIsNotLastQuestion);
    if (getUserNTQ()) {
      setSending(false);
      setShowWarningText(false);
    }
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('userSawLastQuestion', activateNextButton);
    };
  }, []);

  const activateNextButton = () => {
    setFilled(true);
    setShowWarningText(false);
  };

  const userIsNotLastQuestion = () => {
    setFilled(false);
  };

  function handleIsLastPageChange(isLastPage) {
    setIsLastPage(isLastPage);
  }

  const getWindowDimensions = () => {
    return window.innerWidth;
  };

  const handleBack = () => {
    if (activeStep === 0) {
      questionareRef.current.showPreviousQuestions();
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleNext = async () => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isMobile) {
      scroll.scrollToTop();
    }
    const answeredQuestions = getQuestions();
    if (activeStep === 0 && !isLastPage) {
      if (questionareRef.current) {
        if (!validateQuestionnaireSection()) {
          setErrorMessage('Some questions have not been answered.');
          setMessageType('error');
          setTimeout(() => {
            setErrorMessage(null);
          }, 2000);
          return;
        }
        questionareRef.current.showNextQuestions();
      }
      return;
    }

    if (activeStep === 0) {
      if (!validateQuestionnaireSection()) {
        setErrorMessage('Some questions have not been answered.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep === 0) {
      const questionsEvent = new CustomEvent('questionHowMuchWouldYou');
      window.dispatchEvent(questionsEvent);
      const questionValue = getQuestion(
        'how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_'
      );
      if (
        questionValue === null ||
        questionValue === '' ||
        questionValue === undefined ||
        questionValue === '---'
      ) {
        setErrorMessage('Question How Much Would You Estimate... is required');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 2) {
      if (!validateQuestionsBySection('major_complaints')) {
        const event = new CustomEvent('major_complaints');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 3) {
      if (!validateQuestionsBySection('supplements')) {
        const event = new CustomEvent('supplements');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 4) {
      if (!validateQuestionsBySection('diet_history')) {
        const event = new CustomEvent('diet_history');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 5) {
      if (!validateQuestionsBySection('client_history')) {
        const event = new CustomEvent('client_history');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 6) {
      if (!validateQuestionsBySection('health_history')) {
        const event = new CustomEvent('health_history');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 7) {
      if (!validateQuestionsBySection('mercury')) {
        const event = new CustomEvent('mercury');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 8) {
      if (!validateQuestionsBySection('cavitation_/_hidden_infection')) {
        const event = new CustomEvent('cavitation_/_hidden_infection');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 9) {
      if (!validateQuestionsBySection('aluminum')) {
        const event = new CustomEvent('aluminum');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 10) {
      if (!validateQuestionsBySection('lead')) {
        const event = new CustomEvent('lead');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 11) {
      if (!validateQuestionsBySection('general_toxicity')) {
        const event = new CustomEvent('general_toxicity');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 12) {
      if (!validateQuestionsBySection('home_environment')) {
        const event = new CustomEvent('home_environment');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 13) {
      if (!validateQuestionsBySection('mold')) {
        const event = new CustomEvent('mold');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 14) {
      if (!validateQuestionsBySection('lyme')) {
        const event = new CustomEvent('lyme');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 15) {
      if (!validateQuestionsBySection('covid')) {
        const event = new CustomEvent('covid');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 16) {
      if (!validateQuestionsBySection('emf')) {
        const event = new CustomEvent('emf');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 17) {
      if (!validateQuestionsBySection('microbiome_health')) {
        const event = new CustomEvent('microbiome_health');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 18) {
      if (!validateQuestionsBySection('parasites')) {
        const event = new CustomEvent('parasites');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 19) {
      if (!validateQuestionsBySection('sibo')) {
        const event = new CustomEvent('sibo');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 20) {
      if (!validateQuestionsBySection('bacterial_/_viral')) {
        const event = new CustomEvent('bacterial_/_viral');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (activeStep + 1 === 21) {
      if (!validateQuestionsBySection('emotional')) {
        const event = new CustomEvent('emotional');
        window.dispatchEvent(event);
        setErrorMessage('Fill out all questions to continue.');
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    // Clean Up the data
    const { firstName, lastName } = getUserData();
    answeredQuestions.name = `${firstName} ${lastName}`;
    if (getUserNTQ()) {
      answeredQuestions.ntq_id = getUserNTQ();
      answeredQuestions.lead_score_id = getUserLeadScoreId();
    }

    function calculateProgress(currentStepIndex) {
      const totalSteps = steps.length;
      if (currentStepIndex === 0) {
        return 10;
      } else {
        const remainingSteps = totalSteps - 1;
        const progressPerStep = 90 / remainingSteps;
        return 10 + progressPerStep * currentStepIndex;
      }
    }

    let progress = calculateProgress(activeStep);
    if (progress >= getNtqPercentage()) {
      answeredQuestions.ntq_percentage = progress;
    }

    // set completed percentages for first part
    answeredQuestions.questionareAnswers = getMatchedSum();

    if (activeStep !== steps.length - 1) {
      setSending(true);
      await sendData(answeredQuestions);
      setActiveStep(activeStep + 1);
    } else {
      // Last Step
      setErrorMessage('Saving Your Assessment.');
      setMessageType('success');
      setSending(true);

      const event = new CustomEvent('stateUpdated');
      window.dispatchEvent(event);

      await sendData(answeredQuestions);
      let stage = 0;
      if (getProduct() === 'double') {
        stage = 5;
      } else if (getProduct() === '30dcr' || getProduct() === 'lite') {
        stage = 5;
      } else {
        stage = 4;
      }

      setStage(stage);
      await setUserStage(stage);
      await sendData(answeredQuestions);
      setErrorMessage('Your assessment has been saved properly saved.');
      setMessageType('success');
      setTimeout(() => {
        if (getProduct() === '30dcr' || getProduct() === 'lite') {
          navigate('/');
        } else {
          navigate('/watch-video');
        }
      }, 2000);
    }
  };

  const setUserStage = async (stage) => {
    try {
      await axios.post(
        'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod/stage',
        {
          email: getUserSessionEmail(),
          stage: stage,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  const sendData = async (questions) => {
    const userData = getUserData();
    userData.dateofBirth = moment
      .utc(userData.dateofBirth)
      .startOf('day')
      .valueOf();
    delete userData.parent;
    delete userData.stage;
    delete userData.password;
    if (userData.ice) {
      const ice_id = userData.ice.hs_object_id;
      delete userData.ice;
      userData.ice = ice_id;
    }
    questions.email = userData.email;
    const live_with = userData.live_with.toString();
    userData.live_with = live_with;

    try {
      const config = createConfigRequest('edit-ntq', 'post', {
        ...userData,
        ...questions,
      });
      const { data } = await axios(config);
      if (data.ntq) {
        setUserNTQ(data.data.ntq);
      }
      if (data.lead_score) {
        setUserLeadScore(data.data.lead_score);
      }

      if (data.ntq_percentage) {
        setNtqPercentage(data.data.ntq_percentage);
      }

      setSending(false);
    } catch (error) {
      console.log('Error Edit NTQ', error);
      setMessageType('error');
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'There has been a problem with the request, please try again later'
        );
      }
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      throw error;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <StepsLayout>
      <Box display="flex">
        <img src="/3-icon.png" alt="Logo" style={{ height: '84px' }} />
        <Typography component="h1" variant="h3" className="steps-header">
          Assessments
        </Typography>
      </Box>
      {!showForm && (
        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {showForm && (
        <div style={{ marginTop: '20px' }}>
          <Box
            id="header"
            className="frame"
            style={{
              width: getWindowDimensions() - 210 + 'px',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              backgroundColor: 'white',
              padding: '0 10px 0 10px',
              marginBottom: '20px',
            }}
          ></Box>
          <Box
            id="content-questions"
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Box sx={{ backgroundColor: 'white', margin: '7px' }}>
              <Box sx={{ pt: 3, pl: 3 }}>
                <Stepper activeStep={activeStep} sx={{ pt: 2, pb: 2 }}>
                  {steps.map((label, index) => (
                    <Step
                      className={
                        index === activeStep
                          ? 'steper-header-item'
                          : 'steper-header-item-hidden'
                      }
                      key={label}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography
                          sx={{
                            fontWeight: 'bolder',
                            color: '#536d7b',
                            fontSize: { xs: '16px', md: '30px' },
                          }}
                        >
                          {label}
                        </Typography>
                      </Box>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <hr />
              {getStepContent(activeStep)}
            </Box>
            <Box className="forms-button-container">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'space-between' },
                }}
              >
                {
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    &lt; Back
                  </Button>
                }
                <Button
                  style={{
                    width: '200px',
                    fontSize: '17px',
                    borderRadius: '9px',
                    backgroundColor: '#85b4cd',
                  }}
                  disabled={sending}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </Box>
            </Box>
          </Box>
          <div>
            {errorMessage && (
              <Alert sx={{ marginTop: '15px' }} severity={messageType}>
                {errorMessage}
              </Alert>
            )}
          </div>
        </div>
      )}
    </StepsLayout>
  );
}
