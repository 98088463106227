import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import MenuNavbar from '../../components/navBar/navBar';

export default function FormsLayout({ children }) {
  return (
    <Box sx={{ width: '100%' }}>
      <MenuNavbar />
      <Box component="main" sx={{ width: '100%' }}>
        <Container sx={{ maxWidth: '100% !important' }}>
          <Box
            component="main"
            sx={{ backgroundColor: 'white', padding: '35px 35px 50px 35px' }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

FormsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
};
