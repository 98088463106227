import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import Typography from '@mui/material/Typography';
import WatchFirstVideo from './watch-first-video';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { getUserSessionEmail, setStage } from '../../app/services/AuthService';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ConsultRequirements = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    const queryParams = new URLSearchParams(location.search);
    const data = {};

    for (let param of queryParams) {
      data[param[0]] = param[1];
    }
    data['ntq_email'] = getUserSessionEmail();
    data['n1_appointment_for_doubles'] = data['1_appointment_for_doubles'];
    const fetchData = async () => {
      try {
        await axios({
          url: getUrl() + '/create-oncehub',
          method: 'post',
          data: data,
          headers: {
            'x-api-key': getApiKey(),
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();

    return () => {
      document.body.style.backgroundColor = '#e0f2fe';
    };
  }, []);

  useEffect(() => {
    setNewStage();
  }, []);

  const loadNextPage = () => {
    navigate('/profile');
  };

  const setNewStage = async () => {
    try {
      await axios({
        url: getUrl() + '/stage',
        method: 'post',
        data: {
          email: getUserSessionEmail(),
          stage: 2,
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      });
      setStage(2);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ textAlign: 'center', mb: 3 }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '34px',
            textAlign: 'center',
            fontWeight: 'bolder',
            margin: 'auto',
            mt: 5,
            mb: 5,
          }}
        >
          WATCH THE ENTIRE VIDEO!
        </Typography>
        <div
          style={{
            width: '200px',
            height: '3px',
            backgroundColor: 'red',
            margin: 'auto',
          }}
        ></div>
        <div>
          <WatchFirstVideo />
        </div>
        <Box>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '37px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            WAIT!
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            WATCH THIS VIDEO OR YOU MAY NOT BE ABLE TO ATTEND YOUR MEETING!
          </Typography>
        </Box>
        <Box>
          <div
            style={{
              width: '200px',
              height: '3px',
              backgroundColor: 'red',
              margin: 'auto',
            }}
          ></div>
        </Box>
        <Box sx={{ marginLeft: '25%', marginRight: '25%', textAlign: 'left' }}>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '26px',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            RECAP:
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            1. This meeting will be on zoom.
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            2. For Doubles--make sure the other person takes their Neurotoxicity
            Assessment!
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            3. If you need to reschedule before 4 days (96 hours) use the link
            in that same email that was sent to you regarding your appointment!{' '}
            <span style={{ fontStyle: 'italic' }}>
              (You can do this 1x free of charge.)
            </span>
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            4. If you need to reschedule within 96 hours reach out to your Point
            of Contact and they can reschedule you for a $37 reinstatement fee{' '}
            <span style={{ fontStyle: 'italic' }}>
              (this time slot is 100% dedicated to you.)
            </span>
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            5. If you're married (or have someone else that's a part of your
            support system) they need to be there as well!
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
          >
            What You Should Come Ready With:
          </Typography>

          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            1. Neurotoxicity Assessment Completed
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            2. Meta-Oxy Home Test Results
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            3. Know Where to Find Your Zoom Link!
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            4. Be in a Quiet Place Ready to Dive in to What's Been Going On
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            5. If you're married (or have someone else that's a part of your
            support system) they need to be there as well!
          </Typography>
        </Box>
        <Box>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '32px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            Neurotoxicity Assessment
          </Typography>
          <div
            style={{
              width: '200px',
              height: '3px',
              backgroundColor: 'red',
              margin: 'auto',
            }}
          ></div>
        </Box>
        <Typography
          component="p"
          variant="p"
          sx={{
            textAlign: 'center',
            margin: 'auto',
            mt: 5,
            mb: 2,
            fontStyle: 'italic',
          }}
        >
          Buttons to take Assessment will Appear momentarily! Please watch the
          video first!
        </Typography>
        <Button
          sx={{
            width: '270px',
            borderRadius: '0px',
            backgroundColor: '#c90000',
            fontSize: '17px',
            margin: 'auto',
            marginTop: '20px',
            marginBottom: '20px',
            boxShadow: 'none',
            textTransform: 'none !important',
          }}
          onClick={loadNextPage}
          type="butoon"
          variant="contained"
        >
          Next Step
        </Button>
      </Box>
    </BookStepsLayout>
  );
};

export default ConsultRequirements;
