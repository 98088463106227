import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../../app/common/Logo';
import { getUserData, resetUserSession } from '../../app/services/AuthService';

export default function MenuNavbar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userData = getUserData();
  const [stage] = React.useState(userData.stage);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const logoutHandler = () => {
    resetUserSession();
    navigate('/login');
  };

  const formsHandler = () => {
    if (stage > 2) {
      navigate('/assessments');
    }
  };

  const homeHandler = () => {
    navigate('/');
  };

  const profileHandler = () => {
    if (stage > 1) {
      navigate('/profile');
    }
  };

  const renderMenuItems = () => (
    <>
      <MenuItem onClick={homeHandler}>Home</MenuItem>
      {stage > 2 && <MenuItem onClick={profileHandler}>Profile</MenuItem>}
      {stage > 3 && <MenuItem onClick={formsHandler}>Forms</MenuItem>}
      <MenuItem onClick={logoutHandler}>Logout</MenuItem>
    </>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Button color="inherit" onClick={homeHandler}>
          <Logo />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {renderMenuItems()}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Button color="inherit" onClick={homeHandler}>
              <HomeIcon sx={{ mr: 1 }} /> Home
            </Button>
            {stage > 3 && (
              <Button color="inherit" onClick={formsHandler}>
                <DescriptionIcon sx={{ mr: 1 }} />
                Assessments
              </Button>
            )}
            {stage > 2 && (
              <Button color="inherit" onClick={handleProfileMenu}>
                <AccountCircleIcon sx={{ mr: 1 }} />
                {userData.firstName} {userData.lastName}
              </Button>
            )}
            <Menu
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={profileHandler}>
                <AccountCircleIcon sx={{ mr: 1 }} /> Profile
              </MenuItem>
              <MenuItem onClick={logoutHandler}>
                <ExitToAppIcon sx={{ mr: 1 }} /> Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
