import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import WatchVideo from '../VideoPlayer/WatchVideo';

const faqArray = [
  {
    question:
      'Why do different household members with varying diets and health issues get the same result?',
    answer:
      'The test result similarity among household members with different diets and health conditions may suggest exposure to a common stressor. Some individuals may not exhibit symptoms, but high radiation or elevated toxin levels in the house can drive cellular inflammation. Everyone responds differently to stressors, with some showing cellular inflammation and others not. If this occurs, it’s important to inform your coach.',
  },
  {
    question:
      'Why do all household members seem to get dark results? Is the test rigged?',
    answer:
      'Not everyone gets dark results; it varies from person to person. Some individuals, even if very sick or with weakened immune systems, may show low scores due to compromised immunity. There are exceptions, including cases of autoimmune diseases and cancer. The test results depend on individual factors beyond just diet.',
  },
  {
    question: 'Is this test only related to diet?',
    answer:
      'No, the test is not solely related to diet. While diet can contribute to cellular inflammation, the test primarily assesses your toxic stress burden, as explained by Dr. Shane. Factors such as constantly elevated glucose, sugar consumption, and the consumption of bad seed oils can also contribute to cellular inflammation.',
  },
  {
    question: 'Is this test a pH test?',
    answer:
      'No, this test is not a pH test. It measures a specific marker called Melondialdehyde, which is indicative of inflammation in cell membranes. It is not related to pH levels.',
  },
];

export const Faq = () => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: '0px', md: '10px 20px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '60% 40%' },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '0px', md: '30px' },
        }}
      >
        <WatchVideo
          videoId="a7wghfhkim"
          videoStyle={{
            width: '100%',
            height: '500px',
            borderRadius: '10px',
          }}
        />
      </Box>
      <Box style={{ marginLeft: '20px', overflow: 'auto', height: '550px' }}>
        {faqArray.map((faq) => (
          <React.Fragment key={faq.question}>
            <Box sx={{ margin: 3 }} />
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ marginTop: '10px', fontWeight: 'bold' }}
            >
              {faq.question}
            </Typography>
            <Box sx={{ margin: 2 }} />
            <Typography
              variant="body1"
              gutterBottom
              color="text.secondary"
              sx={{ color: 'black', fontSize: '18px' }}
            >
              {faq.answer}
            </Typography>
          </React.Fragment>
        ))}
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          By delving into the science behind this test, we aim to empower you
          with knowledge and understanding. Armed with this information, you can
          harness the full potential of the Cellular Inflammation Test to
          monitor and optimize your cellular health.
        </Typography>
      </Box>
    </Box>
  );
};
