import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import emailValidator from 'email-validator';
import DarkLogo from '../../app/common/DarkLogo';

export default function LoginWithPassword() {
  const { emailFromParams } = useParams();
  const [email, setEmail] = useState(emailFromParams || '');
  const [authenticating, setAuthenticating] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const sendMagicLink = async () => {
    if (!emailValidator.validate(email)) {
      setErrorMessage('Incorrect Email Format');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    setErrorMessage(null);
    setAuthenticating(true);

    try {
      await axios({
        url: getUrl() + '/magic-link',
        method: 'post',
        data: {
          email: email,
          redirectTo: 'profile',
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      });
      setSuccessMessage(
        'An Email has been sent to you with a magic link. Please check your inbox.'
      );
      setTimeout(() => {
        setSuccessMessage(null);
        setAuthenticating(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(
        'There has been a problem with the request, please try again later'
      );
      setTimeout(() => {
        setErrorMessage(null);
        setAuthenticating(false);
      }, 3000);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (email.trim() === '' || password.trim() === '') {
      setErrorMessage('Both username and password are required');
      return;
    }
    setErrorMessage(null);
    setAuthenticating(true);
    const config = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user, true);
        navigate('/');
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  const setEmailForm = (value) => {
    setEmail(value.toLowerCase());
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DarkLogo />
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            value={email}
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={(event) => setEmailForm(event.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link href="/register-secondary" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
            <Link href="/reset-password" variant="body2">
              {'Forgot password?'}
            </Link>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Link href="/login" variant="body2">
              {
                'Login with no password using a Link sent to your email address.'
              }
            </Link>
          </Box>
        </Box>
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
      </div>
    </Container>
  );
}
