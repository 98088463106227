import React from 'react';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// eslint-disable-next-line react/prop-types
const CardItem = ({
  title,
  content,
  finished,
  active,
  url,
  icon,
  number,
  buttonContent = 'Content',
}) => {
  const color = finished ? '#b2f8eb' : active ? '#a1ddff' : 'white';
  const navigate = useNavigate();
  const clickHandler = (url) => {
    if (finished || active) {
      navigate(url);
    }
  };
  const hideCard = !(active || finished) ? 'hide-card' : '';
  return (
    <Card
      className={`home-cards ${hideCard}`}
      style={{
        margin: '90px 45px 0 45px',
        backgroundColor: color,
        height: '450px',
        opacity: finished || active ? 1 : 0.5,
        cursor: active || finished ? 'auto' : 'not-allowed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box
            className="card-number"
            display="flex"
            sx={{ padding: '10px 30px 5px 30px', alignItems: 'center' }}
          >
            <Typography variant="h4">Step</Typography>
            <Typography variant="h3">{number}</Typography>
          </Box>
          <Typography
            className="card-title"
            style={{
              fontWeight: 'bolder',
              color: '#3e4959',
              textAlign: 'center',
            }}
            gutterBottom
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          className="card-description"
          variant="body2"
          color="text.secondary"
          style={{ marginTop: '10px', fontSize: '18px' }}
        >
          {content}
        </Typography>
      </CardContent>
      {(active || finished) && (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            type="button"
            onClick={() => clickHandler(url)}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.65)',
              maxWidth: '200px',
            }}
          >
            {buttonContent}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default CardItem;

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  finished: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
};
