export const getUrl = () => {
  return 'https://api.pompaprogram.com';
};

export const getUrlBasedOnEnv = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    return 'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod';
  }
  return 'https://api-staging.pompaprogram.com';
};

export const getApiKey = () => {
  return 'X53Gi1EC669wQE4SPWjOc8EFADuXHNSCN7laV9Ob';
};

export const createConfigRequest = (path, method, data) => {
  const url = getUrlBasedOnEnv();
  return {
    url: `${url}/${path}`,
    method,
    data,
    headers: {
      'x-api-key': getApiKey(),
      'Content-Type': 'application/json',
    },
  };
};

export const getApiUrl = () => {
  return 'https://api.pompaprogram.com';
};
