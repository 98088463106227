import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearIndeterminate({ status }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (status === 'mock') {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 100);
      return () => clearInterval(interval);
    } else {
      setProgress(0); // Reset progress when status changes
    }
  }, [status]);

  return (
    <>
      {status === 'loading' && (
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          <LinearProgress />
        </Box>
      )}
      {status === 'mock' && (
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </>
  );
}
