/* eslint-disable react/prop-types */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ButtonGroup, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import {
  getQuestion,
  setQuestion,
} from '../../../app/services/QuestionsService';
import { removeDotsAndQuotesFromString } from '../../../app/services/labelService';
import questionsJson from './questions.json';

const previousQuestionIdBaseOnId = (id, data) => {
  for (const parentKey in data) {
    const parentData = data[parentKey];
    const keys = Object.keys(parentData);
    const index = keys.indexOf(id);
    if (index > 0) {
      return keys[index - 1];
    }
  }
  return null;
};

const nextQuestionIdBaseOnId = (id, data) => {
  for (const parentKey in data) {
    const parentData = data[parentKey];
    const keys = Object.keys(parentData);
    const index = keys.indexOf(id);
    if (index !== -1 && index < keys.length - 1) {
      return keys[index + 1];
    }
  }
  return null;
};

export default function QuestionTemplate(props) {
  const [value, setValue] = useState(getQuestion(props.identifier));
  const typeArray =
    typeof props.type === 'string' ? props.type.split(',') : ['', ''];
  const [isChecked, setIsChecked] = useState(
    typeof getQuestion(props.identifier) === 'boolean'
      ? getQuestion(props.identifier)
      : null
  );
  // pick the previous value of the questions
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (typeArray[0] === 'multi_text') {
      const previousQuestion = previousQuestionIdBaseOnId(
        props.identifier,
        questionsJson
      );
      const previousQuestionValue = getQuestion(previousQuestion);
      if (previousQuestionValue) {
        setVisible(true);
      }
    }
    window.addEventListener(props.event, handleErrorEvent);
    return () => {
      window.removeEventListener(
        'questionsUpdated' + props.event,
        handleErrorEvent
      );
    };
  }, []);

  const handleErrorEvent = () => {
    const questionValue = getQuestion(props.identifier);
    if (questionValue === null || questionValue === '') {
      setError(true);
    }
  };

  const handleShowNextQuestionEvent = (event) => {
    const { isChecked } = event.detail;
    if (isChecked) setVisible(true);
    else setVisible(false);
  };

  useEffect(() => {
    window.addEventListener(props.identifier, handleShowNextQuestionEvent);
    return () => {
      window.removeEventListener(props.identifier, handleShowNextQuestionEvent);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('generalQuestionsUpdated', handleErrorEvent);
    return () => {
      window.removeEventListener('generalQuestionsUpdated', handleErrorEvent);
    };
    // eslint-disable-next-line
  }, []);

  const handleTextChange = (newValue) => {
    setValue(newValue);
    setQuestion(props.identifier, newValue);
  };

  const toggleNextQuestion = (checkedValue) => {
    if (props.type === 'boolean_complex') {
      const nextQuestion = nextQuestionIdBaseOnId(
        props.identifier,
        questionsJson
      );
      const toggleNextQuestionEvent = new CustomEvent(nextQuestion, {
        detail: { isChecked: checkedValue },
      });
      window.dispatchEvent(toggleNextQuestionEvent);
    }
  };

  const handleYesClick = () => {
    setIsChecked(true);
    toggleNextQuestion(true);
    setQuestion(props.identifier, true);
  };

  const handleNoClick = () => {
    toggleNextQuestion(false);
    setIsChecked(false);
    setQuestion(props.identifier, false);
  };

  let fontSize = '20px';
  let fontWeight = 'bold';

  return (
    <Box
      sx={{
        width: '100%',
        padding: '10px 20px',
        gridTemplateColumns: { xs: '100%', md: '50% 50%' },
        gridTemplateRows: { xs: '1fr', md: '1fr 1fr' },
        display:
          typeArray[0] !== 'multi_text' ? 'grid' : visible ? 'grid' : 'none',
      }}
    >
      <Box
        className="reusable-text-questionare-label"
        sx={{ fontSize: fontSize, fontWeight: fontWeight }}
      >
        {props.label}
      </Box>
      <Box sx={{ width: '100%' }}>
        {typeArray[0] === 'boolean' || typeArray[0] === 'boolean_complex' ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{}}>
              <ButtonGroup
                size="large"
                variant="contained"
                aria-label="outlined primary button group"
                sx={{ boxShadow: 'none' }}
              >
                <Button
                  style={
                    isChecked === null
                      ? { backgroundColor: 'grey' }
                      : isChecked
                      ? { backgroundColor: '#65c466' }
                      : { backgroundColor: 'grey' }
                  }
                  sx={{
                    borderTopLeftRadius: '20px',
                    borderRight: '0 !important',
                    borderBottomLeftRadius: '20px',
                  }}
                  onClick={handleYesClick}
                >
                  YES
                </Button>
                <Button disabled sx={{ backgroundColor: '#f8f6f6' }}></Button>
                <Button
                  style={
                    isChecked === null
                      ? { backgroundColor: 'grey' }
                      : isChecked
                      ? { backgroundColor: 'grey' }
                      : { backgroundColor: '#ff0000bf' }
                  }
                  sx={{
                    borderTopRightRadius: '20px',
                    borderLeft: '0 !important',
                    borderBottomRightRadius: '20px',
                  }}
                  onClick={handleNoClick}
                >
                  NO
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        ) : typeArray[0] === 'single_text' ? (
          <TextField
            className="reusable-text-questionare-textfield"
            id="outlined-multiline-static"
            rows={12}
            fullWidth
            defaultValue={value}
            error={error && value === null}
            onChange={(event) => handleTextChange(event.target.value)}
          />
        ) : typeArray[0] === 'multi_select' ? (
          <Select
            sx={{
              width: '100%',
              height: '50px',
              marginTop: '20px',
              marginLeft: '15px',
            }}
            value={value}
            labelId={props.identifier}
            id={props.identifier}
            error={error && value === null}
            onChange={(event) => handleTextChange(event.target.value)}
          >
            <MenuItem value=""></MenuItem>
            {props.options.map((item) => (
              <MenuItem key={item} value={removeDotsAndQuotesFromString(item)}>
                {item}
              </MenuItem>
            ))}
          </Select>
        ) : typeArray[0] === 'multi_text' ? (
          <TextField
            id="outlined-multiline-static"
            className="reusable-multi-text-questionare-multi-textfield"
            multiline
            rows={6}
            fullWidth
            error={error && value === ''}
            defaultValue={value}
            onChange={(event) => handleTextChange(event.target.value)}
          />
        ) : (
          <TextField
            id="outlined-multiline-static"
            className="reusable-text-questionare-multi-textfield"
            multiline
            rows={4}
            fullWidth
            error={error && value === ''}
            defaultValue={value}
            onChange={(event) => handleTextChange(event.target.value)}
          />
        )}
      </Box>
    </Box>
  );
}
