import * as React from 'react';
import questions from './questions.json';
import QuestionTemplate from './QuestionTemplate';

export default function DietHistory() {
  return (
    <div className="question-container">
      {Object.entries(questions.diet_history).map(([key, value], index) => (
        <QuestionTemplate
          key={key}
          identifier={key}
          type={value.type}
          label={value.label}
          options={value.options}
          event={'diet_history'}
        />
      ))}
    </div>
  );
}
