import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import Typography from '@mui/material/Typography';
import WatchFirstVideo from './new-watch-first-video';
import NewWatchSecondVideo from './new-watch-second-video';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import emailValidator from 'email-validator';
import { Alert } from '@mui/material';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';

const ConsultRequirement = () => {
  const { email } = useParams();
  const [userInputEmail, setUserInputEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [userNotFound, setUserNotFound] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    return () => {
      document.body.style.backgroundColor = '#e0f2fe';
    };
  }, []);

  const sendMagicLink = async (notifierEmail) => {
    if (!emailValidator.validate(notifierEmail)) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
      setMessage('Invalid email address.');
      setMessageType('error');
      return;
    }

    try {
      await axios({
        url: getUrl() + '/magic-link',
        method: 'post',
        data: {
          email: notifierEmail,
          redirectTo: 'profile',
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      });
      setMessageType('success');
      setMessage('Email Sent.');
      if (userNotFound) {
        setUserNotFound(false);
      }
    } catch (error) {
      let messageTxt = 'Something went wrong. Please try again later.';
      let messageType = 'error';
      console.error(error);
      if (error.response.data.message == 'user does not exist') {
        setUserNotFound(true);
        setMessageType(null);
        setMessage(null);
        return;
      }
      setMessage(messageTxt);
      setMessageType(messageType);
      if (userNotFound) {
        setUserNotFound(false);
      }
    }
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ textAlign: 'center', mb: 3 }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '34px',
            textAlign: 'center',
            fontWeight: 'bolder',
            margin: 'auto',
            mt: 5,
            mb: 5,
          }}
        >
          WATCH THE ENTIRE VIDEO!
        </Typography>
        <div
          style={{
            width: '200px',
            height: '3px',
            backgroundColor: 'red',
            margin: 'auto',
          }}
        ></div>
        <div>
          <WatchFirstVideo />
        </div>
        <Box>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '37px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            WAIT!
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            WATCH THIS VIDEO OR YOU MAY NOT BE ABLE TO ATTEND YOUR MEETING!
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '37px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            WATCH THE NEXT VIDEO BELOW!
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            THIS NEXT VIDEO IS IMPORTANT FOR KNOWING HOW TO TAKE YOUR TEST
          </Typography>
        </Box>
        <Box>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '32px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            Testing Instructions
          </Typography>
          <div>
            <NewWatchSecondVideo />
          </div>
          <div
            style={{
              width: '200px',
              height: '3px',
              backgroundColor: 'red',
              margin: 'auto',
            }}
          ></div>
        </Box>
        <Box sx={{ marginLeft: '25%', marginRight: '25%', textAlign: 'left' }}>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '26px',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            RECAP:
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            1. This meeting will be on zoom.
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            2. For Doubles--make sure the other person takes their Neurotoxicity
            Assessment!
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            3. If you need to reschedule before 4 days (96 hours) use the link
            in that same email that was sent to you regarding your appointment!{' '}
            <span style={{ fontStyle: 'italic' }}>
              (You can do this 1x free of charge.)
            </span>
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            4. If you need to reschedule within 96 hours reach out to your Point
            of Contact and they can reschedule you for a $37 reinstatement fee{' '}
            <span style={{ fontStyle: 'italic' }}>
              (this time slot is 100% dedicated to you.)
            </span>
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            5. If you're married (or have someone else that's a part of your
            support system) they need to be there as well!
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
          >
            What You Should Come Ready With:
          </Typography>

          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            1. Neurotoxicity Assessment Completed
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            2. Meta-Oxy Home Test Results
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            3. Know Where to Find Your Zoom Link!
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            4. Be in a Quiet Place Ready to Dive in to What's Been Going On
          </Typography>
          <Typography component="h5" variant="h5" sx={{ mt: 1.5, mb: 1.5 }}>
            5. If you're married (or have someone else that's a part of your
            support system) they need to be there as well!
          </Typography>
        </Box>
        <Box>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontSize: '32px',
              textAlign: 'center',
              fontWeight: 'bolder',
              margin: 'auto',
              mt: 5,
              mb: 2,
            }}
          >
            Neurotoxicity Assessment
          </Typography>
          <div
            style={{
              width: '200px',
              height: '3px',
              backgroundColor: 'red',
              margin: 'auto',
            }}
          ></div>
        </Box>
        <div>
          {!email ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  textAlign: 'center',
                  margin: 'auto',
                  mt: 5,
                  fontStyle: 'italic',
                }}
              >
                To take your NTQ we will send a Magic Link to your inbox at:
              </Typography>
              <TextField
                sx={{
                  width: '270px',
                  margin: 'auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
                value={userInputEmail}
                onChange={(e) =>
                  setUserInputEmail(e.target.value.toLowerCase())
                }
              />
            </Box>
          ) : (
            <Typography
              component="p"
              variant="p"
              sx={{
                textAlign: 'center',
                margin: 'auto',
                mt: 5,
                mb: 2,
                fontStyle: 'italic',
              }}
            >
              To take your NTQ we will send a Magic Link to your inbox at{' '}
              {email}
            </Typography>
          )}

          <Button
            sx={{
              width: '270px',
              borderRadius: '0px',
              backgroundColor: '#c90000',
              fontSize: '17px',
              margin: 'auto',
              marginTop: '20px',
              marginBottom: '20px',
              boxShadow: 'none',
              textTransform: 'none !important',
            }}
            onClick={() => sendMagicLink(email ? email : userInputEmail)}
            type="butoon"
            variant="contained"
          >
            Send me magic Link
          </Button>
        </div>
        <div style={{ margin: 'auto' }}>
          {message && (
            <Alert
              sx={{ marginTop: '15px', width: '300px' }}
              severity={messageType}
            >
              {message}
            </Alert>
          )}
          {userNotFound && (
            <Alert
              sx={{ marginTop: '15px', width: '300px' }}
              severity="warning"
            >
              Hmmm. We cant seem to locate a user by this email address. Did you
              possibly use a different email? Or, click{' '}
              <a href="https://apply.pompaprogram.com/register-secondary">
                here
              </a>{' '}
              to register as a new user and take your NTA.
            </Alert>
          )}
        </div>
      </Box>
    </BookStepsLayout>
  );
};

export default ConsultRequirement;
