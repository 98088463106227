import React, { useEffect } from 'react';
import scriptjs from 'scriptjs';
import { getUserData } from '../../services/AuthService';

export function GenericScheduleEmbed({
  iframeId,
  iframePage,
  email = '',
  name = '',
  lastName = '',
  phone = '',
}) {
  const user = getUserData();

  const generateDataSoQryPrm = () => {
    if (!user && email === '') return '';

    if (email) {
      let params = '';
      if (email) {
        email = email === 'null' ? '' : email;
        params += `email=${email}`;
      }
      if (name) {
        name = name === 'null' ? '' : name;
        params += `&name=${name}`;
      }
      if (lastName) {
        lastName = lastName === 'null' ? '' : lastName;
        params += `&last name=${lastName}`;
      }
      if (phone) {
        phone = phone === 'null' ? '' : phone;
        params += `&mobile=${phone}`;
      }
      return params;
    }

    let parameters = `email=${user.email}`;
    if (user.firstName) {
      parameters += `&name=${user.firstName}`;
    }
    if (user.lastName) {
      parameters += `&last name=${user.lastName}`;
    }
    return parameters;
  };

  useEffect(() => {
    scriptjs('https://cdn.oncehub.com/mergedjs/so.js');
  }, [iframeId]);

  return (
    <div
      key={iframeId}
      style={{
        width: '100%',
        height: '548px',
        maxWidth: '900px',
      }}
      id={iframeId}
      data-so-page={iframePage}
      data-height="550"
      data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 1900px;"
      data-so-qry-prm={generateDataSoQryPrm() ? generateDataSoQryPrm() : ''}
      data-psz="00"
    ></div>
  );
}
