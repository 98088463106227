import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Loader from '../loader/Loader';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import { renderToString } from 'react-dom/server';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Create() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orderId, setOrderId] = useState('');
  // eslint-disable-next-line
  const [firstName, setFirstName] = useState('');
  // eslint-disable-next-line
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState('loading');
  useEffect(() => {
    // Function to dynamically load the GTM script
    const loadGTM = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WNR375RJ';
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WNR375RJ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    };

    // Load GTM
    loadGTM();
    const emailParam = searchParams.get('Email');
    const orderIdParam = searchParams.get('orderId');

    if (emailParam && orderIdParam) {
      setEmail(emailParam);
      setOrderId(orderIdParam);

      // Wait for 2 seconds and then redirect to the new page with parameters
      setTimeout(() => {
        navigate(
          `/which-appointment?Email=${emailParam}&orderId=${orderIdParam}`
        );
      }, 2000);
    }
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    setStatus('loading');
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Typography component="h2" variant="h5">
          Please stand by...
        </Typography>
        <Loader status={status} />
      </Box>
      {status === 'user already exist' && (
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <Typography
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            component="h3"
            variant="h5"
          >
            please set your password
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      )}

      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
