import React from 'react';
import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds';

const VideoTriage = () => {
  return (
    <WistiaProvider>
      <WistiaPlayer
        hashedId="v74d3ty9rv"
        videoFoam={false}
        style={{
          height: '475px',
          width: '850px',
        }}
      />
    </WistiaProvider>
  );
};

export default VideoTriage;
