import React, { useState, useEffect } from 'react';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GenericScheduleEmbed } from '../../app/common/Embed/GenericScheduleEmbed';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import { getUrl } from '../../app/api/agent';
import VideoTriage from '../book-new-call/VideoTriage';
import { useFetchSamcartCustomer } from '../../app/services/fetchSamcartCustomer';

export const OfficeManagerCallBooking = () => {
  const { customerData, loading } = useFetchSamcartCustomer(); // Destructure loading state and customerData
  const { firstName: name, lastName, phone: mobile, email } = customerData;

  const [toggle, setToggle] = useState(false);
  const [mainIframePage, setMainIframePage] = useState();
  const [mainIframeId, setMainIframeId] = useState();
  const [secondaryIframePage, setSecondaryIframePage] = useState();
  const [secondaryIframeId, setSecondaryIframeId] = useState();

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 100);
    fetch(getUrl() + `/split-percentage`)
      .then((response) => response.json())
      .then((data) => {
        const percentage = data.percentage;
        const useMainCalendar = randomNumber <= percentage;
        setMainIframePage(
          useMainCalendar ? 'pojoiwewnsesrtyqioeslwiogbs' : 'additionaltimes'
        );
        setMainIframeId(
          useMainCalendar
            ? 'SOIDIV_pojoiwewnsesrtyqioeslwiogbs'
            : 'SOIDIV_additionaltimes'
        );
        setSecondaryIframePage(
          useMainCalendar ? 'additionaltimes' : 'pojoiwewnsesrtyqioeslwiogbs'
        );
        setSecondaryIframeId(
          useMainCalendar
            ? 'SOIDIV_additionaltimes'
            : 'SOIDIV_pojoiwewnsesrtyqioeslwiogbs'
        );
      })
      .catch((error) => console.error('Failed to fetch main calendar', error));
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="85%" message="Almost Complete..." />
        </Typography>
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          maxWidth: '900px',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: '20px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 2,
            mt: 2,
            '@media (max-width: 700px)': {
              fontSize: '22px',
            },
          }}
        >
          Step 1: Watch the Video Below
        </Typography>

        <VideoTriage />
        <Box mb={4} />
      </div>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          maxWidth: '900px',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '40px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold',
            paddingBottom: '20px',
            margin: 'auto',
            mb: 2,
            mt: 2,
            '@media (max-width: 700px)': {
              fontSize: '22px',
            },
          }}
        >
          Step 2: Book Your Call
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 4,
            mt: 0,
            '@media (max-width: 700px)': {
              fontSize: '22px',
              mb: 2,
            },
          }}
        >
          If there are no times that work for you, please book an appointment
          and you will be able to reschedule when we reach out to you via text.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '16px',
            textAlign: 'center',
            margin: 'auto',
            mb: 4,
            mt: 0,
            '@media (max-width: 700px)': {
              fontSize: '14px',
            },
          }}
        >
          Please use the same email that you purchased with and verify your time
          zone before booking. If you don't see a scheduler below, please{' '}
          <Box
            component="span"
            sx={{
              color: 'rgb(90, 90, 190)',
              cursor: 'pointer',
            }}
            onClick={refreshPage}
          >
            click here
          </Box>{' '}
          to refresh the page now.
        </Typography>

        <div
          style={{
            display: !toggle ? 'block' : 'none',
            width: '80%',
            height: '548px',
            maxWidth: '900px',
          }}
        >
          {!loading && mainIframePage && (
            <GenericScheduleEmbed
              iframeId="SOIDIV_CellularHealthConsultation"
              iframePage="CellularHealthConsultation"
              email={email}
              name={name}
              lastName={lastName}
              phone={mobile}
            />
          )}
        </div>

        <Box mb={2} />
      </div>
    </BookStepsLayout>
  );
};
