import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Logo from '../../app/common/Logo';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import {
  setUserData,
  setUserSessionEmail,
} from '../../app/services/AuthService';
import emailValidator from 'email-validator';

const Register = () => {
  const [email, setEmail] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [verifyPasswordError, setVerifyPasswordError] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');
  const navigate = useNavigate();

  const setEmailForm = (value) => {
    setEmail(value.toLowerCase());
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!emailValidator.validate(email)) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
      setMessage('Invalid email address');
      setMessageType('error');
      return;
    }
    if (
      !password.match(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
      )
    ) {
      setPasswordError(
        'Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8-16 characters long.'
      );
      return;
    } else {
      setPasswordError(null);
    }
    if (password !== verifyPassword) {
      setVerifyPasswordError('passwords do not match');
      return;
    } else {
      setVerifyPasswordError(null);
    }

    setMessage(null);
    setAuthenticating(true);
    const config = {
      url: getUrl() + '/register',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(() => {
        setMessage(
          'Registration Successful you will be automatically logged in.'
        );
        setEmail('');
        setPassword('');
        setAuthenticating(false);
        setMessageType('success');
        setTimeout(() => {
          login();
        }, 3000);
      })
      .catch((error) => {
        setMessageType('error');
        setAuthenticating(false);
        if (error.response.status === 401) {
          setMessage(error.response.data.message);
        } else {
          setMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };
  function login() {
    const loginconfig = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(loginconfig)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        navigate('/');
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setMessage(error.response.data.message);
        } else {
          setMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            value={email}
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={(event) => setEmailForm(event.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {passwordError && <Alert severity={'error'}>{passwordError}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            value={verifyPassword}
            onChange={(event) => setVerifyPassword(event.target.value)}
            name="password"
            label="Verify Password"
            type="password"
            id="verify-password"
            autoComplete="verify-password"
          />
          {verifyPasswordError && (
            <Alert severity={'error'}>{verifyPasswordError}</Alert>
          )}
          <Button
            disabled={authenticating}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {'Already have account? Sign In'}
              </Link>
            </Grid>
          </Grid>
          <div style={{ marginTop: '10px' }}>
            {message && <Alert severity={messageType}>{message}</Alert>}
          </div>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
