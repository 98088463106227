import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ScheduleOnceEmbed from '../../app/common/Embed/ScheduleOnceEmbed';
import { getProduct } from '../../app/services/AuthService';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ScheduleOnceCovidSingleEmbed from '../../app/common/Embed/ScheduleOnceCovidSingleEmbed';
import ScheduleOnceCovidDoubleEmbed from '../../app/common/Embed/ScheduleOnceCovidDoubleEmbed';
import ScheduleOnceThyroidSingleEmbed from '../../app/common/Embed/ScheduleOnceThyroidSingleEmbed';
import ScheduleOnceThyroidDoubleEmbed from '../../app/common/Embed/ScheduleOnceThyroidDoubleEmbed';
import DoubleConsultationEmbed from '../../app/common/Embed/DoubleConsultationEmbed';
import CloseDoorSingleEmbed from '../../app/common/Embed/CloseDoorSingleEmbed';
import CloseDoorDoubleEmbed from '../../app/common/Embed/CloseDoorDoubleEmbed';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import VideoEmbed from './VideoEmbed';
import AutoimmuneSingle from '../../app/common/Embed/AutoimmuneSingle';
import AutoimmuneDouble from '../../app/common/Embed/AutoimmuneDouble';
import DiabetesSingle from '../../app/common/Embed/DiabetesSingle';
import DiabetesDouble from '../../app/common/Embed/DiabetesDouble';
import { FormControlLabel, Switch } from '@mui/material';
import { GenericScheduleEmbed } from '../../app/common/Embed/GenericScheduleEmbed';

const BookNewCall = () => {
  const [toggle, setToggle] = React.useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    return () => {
      document.body.style.backgroundColor = '#e0f2fe';
    };
  }, []);
  const getEmbed = () => {
    switch (getProduct()) {
      case 'covid-single':
        return <ScheduleOnceCovidSingleEmbed />;
      case 'covid-double':
        return <ScheduleOnceCovidDoubleEmbed />;
      case 'close_door-single':
        return <CloseDoorSingleEmbed />;
      case 'close_door-double':
        return <CloseDoorDoubleEmbed />;
      case 'thyroid-single':
        return <ScheduleOnceThyroidSingleEmbed />;
      case 'thyroid-double':
        return <ScheduleOnceThyroidDoubleEmbed />;
      case 'double-consultation':
        return <DoubleConsultationEmbed />;
      case 'autoimmune-single':
        return <AutoimmuneSingle />;
      case 'autoimmune-double':
        return <AutoimmuneDouble />;
      case 'diabetes-single':
        return <DiabetesSingle />;
      case 'diabetes-double':
        return <DiabetesDouble />;
      default:
        return <ScheduleOnceEmbed />;
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#f9f9f9',
          height: '190px',
          textAlign: 'center',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '26px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
          }}
        >
          Thank you for your Order!
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '26px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
          }}
        >
          NOW YOU NEED TO BOOK YOUR APPOINTMENT BELOW!
        </Typography>
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '40px',
          paddingTop: '20px',
        }}
      >
        <VideoEmbed />
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '25px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 4,
            mt: 2,
          }}
        >
          Please make sure you use the same email address you used for your
          purchase.
        </Typography>
        <div
          style={{
            display: !toggle ? 'block' : 'none',
            width: '80%',
            height: '548px',
            maxWidth: '900px',
          }}
        >
          {getEmbed()}
        </div>
        <div
          style={{
            width: '80%',
            height: '585px',
            maxWidth: '900px',
            display: toggle ? 'block' : 'none',
          }}
        >
          <div
            style={{
              border: '5px solid #9ec2e5',
              borderRadius: '4px',
            }}
          >
            <Typography
              component="h5"
              variant="h5"
              style={{ textAlign: 'center' }}
            >
              Additional Times
            </Typography>
            <GenericScheduleEmbed
              iframeId={'SOIDIV_additionaltimes'}
              iframePage={'additionaltimes'}
            />
          </div>
        </div>
        {/*<Typography*/}
        {/*  component="h2"*/}
        {/*  variant="h2"*/}
        {/*  sx={{*/}
        {/*    fontSize: '25px',*/}
        {/*    textAlign: 'center',*/}
        {/*    margin: 'auto',*/}
        {/*    mb: 2,*/}
        {/*    mt: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  For additional times and availability, click the toggle button below.*/}
        {/*</Typography>*/}
        {/*<FormControlLabel*/}
        {/*  control={*/}
        {/*    <Switch*/}
        {/*      checked={toggle}*/}
        {/*      onChange={() => {*/}
        {/*        setToggle(!toggle);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  label="More Time Options"*/}
        {/*/>*/}
        <Box mb={2} />
      </div>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#f9f9f9',
          height: '310px',
          textAlign: 'center',
          padding: '20px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '22px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
          }}
        >
          If you don’t see the scheduler press the refresh button below.
        </Typography>
        <Button
          sx={{
            mb: 2,
            width: '270px',
            borderRadius: '0px',
            backgroundColor: '#c90000',
            fontSize: '17px',
            margin: 'auto',
            marginTop: '20px',
            marginBottom: '20px',
            boxShadow: 'none',
            textTransform: 'none !important',
          }}
          onClick={refreshPage}
          type="butoon"
          variant="contained"
        >
          Refresh Page
        </Button>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '20px !important',
            marginBottom: '20px !important',
            fontSize: '20px',
            fontStyle: 'italic',
            textAlign: 'center',
            fontWeight: 'bold',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          You will also receive tracking in 1-2 business days to the email you
          purchased under.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ height: '200px', textAlign: 'center', padding: '20px' }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontStyle: 'italic',
            margin: 'auto',
          }}
        >
          We will be sending your test kit via FedEX within the next business
          day!
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontStyle: 'italic',
            margin: 'auto',
            marginTop: '0 !important',
          }}
        >
          We will also be personally reaching out to you via text after you book
          your appointment.
        </Typography>
      </Box>
    </BookStepsLayout>
  );
};

export default BookNewCall;
