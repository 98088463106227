import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import { getUserData } from '../services/AuthService';
import MenuNavbar from '../../components/navBar/navBar';

export default function PremiumLayout({ children }) {
  const user = getUserData();
  LogRocket.init('9cwh6y/pompa-program');
  LogRocket.identify(user.email, {
    name: user.firstName + ' ' + user.lastName,
    email: user.email,
    stage: user.stage,
    productName: user.productName,
  });

  return (
    <Box sx={{ width: '100%' }}>
      <MenuNavbar />
      <Box component="main" sx={{ width: '100%' }}>
        <Container sx={{ maxWidth: '100% !important' }}>
          <Box component="main" sx={{ paddingTop: '63px' }}>
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

PremiumLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
