import * as React from 'react';
import Box from '@mui/material/Box';
import styled, { keyframes } from 'styled-components';

const stripesAnimation = keyframes`
from {
  background-position: 40px 0;
}
to {
  background-position: 0 0;
}
`;

// Create a styled component based on Box that applies the animation
const AnimatedBox = styled(Box)`
  width: ${({ theme, width }) => width}; // Use width passed as prop
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: ${stripesAnimation} 2s linear infinite reverse;
  -webkit-background-size: 40px 40px;
  background-color: #428bca;
  line-height: 40px;
  height: 40px;
  font-style: normal;
`;

export default function PompaProgressBar({ width, message }) {
  return (
    <Box sx={{ width: '100%', backgroundColor: '#f5f5f5' }}>
      <AnimatedBox width={width}>
        <Box
          sx={{
            fontSize: '14px',
            color: 'white',
          }}
        >
          {message}
        </Box>
      </AnimatedBox>
    </Box>
  );
}
