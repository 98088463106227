import React, { useEffect } from 'react';
import PremiumLayout from '../../app/common/PremiumLayout';
import CardItem from '../cardItem/CardItem';
import Typography from '@mui/material/Typography';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import {
  getProduct,
  getUserData,
  isUserParent,
} from '../../app/services/AuthService';
import Box from '@mui/material/Box';
import { getNeuroToxicityScore } from '../../app/services/QuestionsService';
import { Bookings } from '../bookings/bookings';

const Home = () => {
  const userData = getUserData();
  const [stage] = React.useState(userData.stage);
  const [userParent] = React.useState(isUserParent());
  const [product] = React.useState(
    getProduct() ? getProduct() : 'covid-single'
  );
  const [isParent, setIsParent] = React.useState(false);
  const [score] = React.useState(
    getNeuroToxicityScore() <= 49 ? null : getNeuroToxicityScore()
  );

  useEffect(() => {
    setIsParent(isUserParent());
  }, []);

  const showStage = !product.includes('double') && isParent ? stage + 2 : stage;
  function getContentDouble() {
    switch (showStage) {
      case 2:
        return 'Lets Start by Booking Your Call';
      case 3:
        if (product.includes('double') && isParent)
          return "Now let's invite your family";
        return 'Now Lets Get Your Contact Information';
      case 4:
        return 'Lets Continue with Your Health Assessment';
      case 5:
        return 'Last Step! Almost There';
      case 6:
        return 'Great Job! You are Done';
      default:
        return 'WELCOME TO POMPA PROGRAM';
    }
  }

  function getContent() {
    switch (stage) {
      case 1:
        return 'Lets Start by Booking Your Call';
      case 2:
        return 'Now Lets Get Your Contact Information';
      case 3:
        return 'Lets Continue with Your Health Assessment';
      case 4:
        if (product === '30dcr' || product === 'lite') {
          return 'Great Job! You are Done';
        }
        return 'Last Step! Almost There';
      case 5:
        if (product.includes('double') && isParent)
          return "Now let's invite your family";
        return 'Great Job! You are Done';
      default:
        return 'WELCOME TO POMPA PROGRAM';
    }
  }

  const doubleRender = (
    <PremiumLayout>
      <Typography
        className="home-title"
        component="h1"
        variant="h4"
        style={{
          textAlign: 'center',
          marginBottom: '50px',
          fontWeight: 'bolder',
        }}
      >
        {getContentDouble().toUpperCase()}
        <Box>
          {score && (
            <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
              Neurotoxicity Score: {score}
            </Typography>
          )}
        </Box>
      </Typography>
      <Box
        className="card-container"
        sx={{
          width: '100%',
          display: 'grid',
          gridAutoColumns: '1fr',
          gridAutoFlow: 'column',
          height: '80vh',
        }}
      >
        {product === 'double' && (
          <CardItem
            title={'INVITE OTHERS'}
            content={
              'Send an invitation to take the Assessment to the family member(s) joining you!'
            }
            icon={
              userParent ? (
                <Looks4OutlinedIcon sx={{ fontSize: 40 }} />
              ) : (
                <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
              )
            }
            finished={stage > 2}
            number={product === 'double' ? 1 : 2}
            active={showStage === 2}
            url={'/child-profile'}
            buttonContent={
              'Click Here to Send an Invitation to a Family Member'
            }
          />
        )}
        <CardItem
          title={'CONTACT FORM'}
          content={
            'Tell us about Yourself! We look forward to getting to know you!'
          }
          icon={
            userParent ? (
              <LooksTwoOutlinedIcon sx={{ fontSize: 40 }} />
            ) : (
              <LooksOneOutlinedIcon sx={{ fontSize: 40 }} />
            )
          }
          number={product === 'double' ? 2 : 1}
          finished={!(product === 'double') ? showStage > 2 : stage > 3}
          active={!(product === 'double') ? showStage === 2 : stage === 3}
          url={'/profile'}
          buttonContent={'Click Here to Fill Your Contact Info'}
        />
        <CardItem
          title={'ASSESSMENT'}
          content={
            'Taking this assessment will help our team get a better understanding of what you are dealing with and prohibiting you from succeeding.'
          }
          icon={
            userParent ? (
              <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
            ) : (
              <LooksTwoOutlinedIcon sx={{ fontSize: 40 }} />
            )
          }
          finished={showStage > 4}
          number={product === 'double' ? 3 : 2}
          active={showStage === 4}
          url={'/assessments'}
          buttonContent={'Click Here to Create Your Assessment'}
        />
        {product !== '30dcr' && product !== 'lite' && (
          <CardItem
            title={'WATCH THE VIDEO'}
            content={
              'IMPORTANT! Please make sure to watch this 2-3 minute video that will help answer all the questions you may have.'
            }
            icon={
              userParent ? (
                <Looks4OutlinedIcon sx={{ fontSize: 40 }} />
              ) : (
                <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
              )
            }
            finished={
              (product === 'single' && stage > 5) ||
              (product === 'double' && stage > 5) ||
              stage > 4
            }
            active={showStage === 5}
            number={product === 'double' ? 4 : 3}
            url={'/watch-video'}
            buttonContent={'Click Here to Watch the Final Video'}
          />
        )}
      </Box>
    </PremiumLayout>
  );

  if (getProduct() === 'double') {
    return doubleRender;
  }

  return (
    <PremiumLayout>
      {/** This is commented out because Oncehub is not returning the right data, but it should be uncommented once it is fixed */}
      {/**isParent && <Bookings /> **/}
      <br />
      <br />
      <Typography className="home-title" component="h1" variant="h3">
        {getContent()}
        <Box>
          {score && (
            <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
              Neurotoxicity Score: {score}
            </Typography>
          )}
        </Box>
      </Typography>
      <br />
      <Box
        className="card-container"
        sx={{
          width: '100%',
          display: 'grid',
          gridAutoColumns: '1fr',
          gridAutoFlow: 'column',
          backgroundColor: '#f2faff',
          borderRadius: '10px',
          border: '1px solid #3e4a59',
          justifyContent: 'space-between',
          paddingBottom: '40px',
        }}
      >
        {userParent && (
          <CardItem
            title={'BOOK YOUR CALL'}
            content={
              "We very much are looking forward to meeting with you, So please don't forget to schedule!"
            }
            icon={<LooksOneOutlinedIcon sx={{ fontSize: 40 }} />}
            number={1}
            finished={stage > 1}
            active={stage === 1}
            url={'/book-call'}
            buttonContent={'Click Here to Book Your Call'}
          />
        )}
        <CardItem
          title={'CONTACT FORM'}
          content={
            'Tell us about yourself! We look forward to getting to know you!'
          }
          icon={
            userParent ? (
              <LooksTwoOutlinedIcon sx={{ fontSize: 40 }} />
            ) : (
              <LooksOneOutlinedIcon sx={{ fontSize: 40 }} />
            )
          }
          number={userParent ? 2 : 1}
          finished={stage > 2}
          active={stage === 2}
          url={'/profile'}
          buttonContent={'Click Here to Fill Your Contact Info'}
        />
        <CardItem
          title={'ASSESSMENT'}
          content={
            'Taking this assessment will help our team get a better understanding of what you are dealing with and prohibiting you from succeeding.'
          }
          icon={
            userParent ? (
              <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
            ) : (
              <LooksTwoOutlinedIcon sx={{ fontSize: 40 }} />
            )
          }
          finished={
            getProduct() === '30dcr' || getProduct() === 'lite'
              ? stage >= 5
              : stage > 3
          }
          number={userParent ? 3 : 2}
          active={
            getProduct() === '30dcr' || getProduct() === 'lite'
              ? stage === 4
              : stage === 3
          }
          url={'/assessments'}
          buttonContent={'Click Here to Create Your Assessment'}
        />
        {product !== '30dcr' && product !== 'lite' && (
          <CardItem
            title={'WATCH THE VIDEO'}
            content={
              'IMPORTANT! Please make sure to watch this 2-3 minute video that will help answer all the questions you may have.'
            }
            icon={
              userParent ? (
                <Looks4OutlinedIcon sx={{ fontSize: 40 }} />
              ) : (
                <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
              )
            }
            finished={
              (product === 'single' && stage > 5) ||
              (product === 'double' && stage > 5) ||
              stage > 4
            }
            active={showStage === 5}
            number={product === 'double' ? 4 : 3}
            url={'/watch-video'}
            buttonContent={'Click Here to Watch the Final Video'}
          />
        )}
        {product.includes('double') && isParent && (
          <CardItem
            title={'INVITE OTHERS'}
            content={
              'Send an invitation to take the Assessment to the family member(s) joining you!'
            }
            icon={
              userParent ? (
                <Looks4OutlinedIcon sx={{ fontSize: 40 }} />
              ) : (
                <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
              )
            }
            finished={stage > 5}
            number={userParent ? 5 : 4}
            active={stage === 5}
            url={'/child-profile'}
            buttonContent={
              'Click Here to Send an Invitation to a Family Member'
            }
          />
        )}
      </Box>
      <br />
    </PremiumLayout>
  );
};

export default Home;
