import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#3e4959',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: '#3e4959',
        },
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#3e4959',
      light: '#326a87',
    },
    secondary: {
      main: '#7f7f7f',
    },
    background: {
      default: '#e0f2fe',
      paper: '#ffffff',
    },
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default theme;
