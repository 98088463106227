import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getApiKey, getUrl } from '../../app/api/agent';
import StepsLayout from '../../app/common/StepsLayout';
import axios from 'axios';
import { Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import emailValidator from 'email-validator';

export default function DeleteForm() {
  const [email, handleEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [disableButton, setDisableButton] = React.useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = React.useState(false);
  const [messageType, setMessageType] = useState('success');
  // eslint-disable-next-line
  const [messagePasswordType, setMessagePasswordType] = useState('success');
  // eslint-disable-next-line
  const [errorPasswordMessage, setErrorPasswordMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [user, setUser] = useState({});
  const [parent, setParent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!emailValidator.validate(email)) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorMessage('Invalid email address');
      setMessageType('error');
      return;
    }

    setDisableButton(true);
    const config = {
      url: getUrl() + '/search',
      method: 'post',
      data: {
        email: email,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setDisableButton(false);
        setUser(response.data);
        setParent(response.data.parent);
      })
      .catch(() => {
        setDisableButton(false);
        setErrorMessage(
          'There has been a problem with the request, please try again later'
        );
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      });
  };

  const changeParent = () => {
    const config = {
      url: getUrl() + '/parent',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setParent(response.data.parent);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const deleteUser = () => {
    setDisableDeleteButton(true);

    const config = {
      url: getUrl() + '/delete',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(() => {
        setErrorMessage('The User has been deleted Successfully');
        setMessageType('success');
        setTimeout(() => {
          setErrorMessage('');
          handleEmail('');
          setUser({});
          setDisableDeleteButton(false);
        }, 2500);
      })
      .catch((error) => {
        setDisableDeleteButton(false);
        setErrorMessage(
          error.response.data.message || "The User hasn't been deleted"
        );
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      });
  };

  const userRender = (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
      }}
      sx={{ mt: 3 }}
    >
      <Box>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          Info:
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Email -
          </Typography>
          {user.email}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            First Name -
          </Typography>
          {user.firstName}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Last Name -
          </Typography>
          {user.lastName}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Date of Birth -
          </Typography>
          {user.dateofBirth}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Gender -
          </Typography>
          {user.gender}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Height -
          </Typography>
          {user.height}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Weight -
          </Typography>
          {user.weight}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Hours Worked per Week -
          </Typography>
          {user.hoursworkedperweek}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Marital Status -
          </Typography>
          {user.maritalStatus}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Occupation -
          </Typography>
          {user.occupation}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            User Stage -
          </Typography>
          {user.stage}
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Open NTQ link to hubspot:
          </Typography>
          <Link
            target="_blank"
            href={`https://app.hubspot.com/contacts/20336610/record/2-10949796/${user.ntq}/`}
            variant="body1"
            sx={{ color: 'black', fontSize: '18px' }}
          >
            {user.ntq}
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Typography
            component="span"
            sx={{
              color: 'black',
              fontSize: '18px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              marginRight: '8px',
            }}
          >
            Open LeadScore Object to hubspot:
          </Typography>
          <Link
            target="_blank"
            href={`https://app.hubspot.com/contacts/20336610/record/2-11879981/${user.lead_score}/`}
            variant="body1"
            sx={{ color: 'black', fontSize: '18px' }}
          >
            {user.lead_score}
          </Link>
        </Typography>
        <Typography
          component="span"
          sx={{
            color: 'black',
            fontSize: '18px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
            marginRight: '8px',
          }}
        >
          Change User Type:
        </Typography>
        <Button onClick={changeParent} sx={{ backgroundColor: '#85b4cd' }}>
          Change to {!parent ? 'Parent' : 'Child'}
        </Button>
        <div style={{ marginTop: '10px', maxWidth: '400px' }}>
          {errorPasswordMessage && (
            <Alert severity={messagePasswordType}>{errorPasswordMessage}</Alert>
          )}
        </div>
        {user.stage >= 2 && (
          <Box
            sx={{ mt: 4 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="p" className="input-label">
              Enter Password to Delete Email:
            </Typography>
            <TextField
              name="password"
              required
              type="password"
              id="password"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            />
          </Box>
        )}
        <Button
          type="button"
          disabled={disableDeleteButton}
          onClick={deleteUser}
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Delete User
        </Button>
      </Box>
    </Box>
  );

  return (
    <StepsLayout>
      <Box display="flex">
        <Typography component="h1" variant="h3" className="steps-header">
          Delete Account
        </Typography>
      </Box>
      <Box component="form" display="grid" noValidate onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: 'white',
            padding: '50px 30px 30px 30px',
            borderRadius: '10px',
          }}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Email
              </Typography>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                type="email"
                id="email"
                autoFocus
                onChange={(event) => handleEmail(event.target.value)}
                value={email}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          disabled={disableButton}
          fullWidth
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Find User
        </Button>
        <div style={{ marginTop: '10px', maxWidth: '400px' }}>
          {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
        </div>
      </Box>
      {disableButton && <CircularProgress />}
      {user.email && userRender}
    </StepsLayout>
  );
}
