import * as React from 'react';
import questions from './questions.json';
import QuestionTemplate from './QuestionTemplate';

export default function MicrobiomeHealth() {
  return (
    <div className="question-container">
      {Object.entries(questions.microbiome_health).map(
        ([key, value], index) => (
          <QuestionTemplate
            key={key}
            identifier={key}
            type={value.type}
            label={value.label}
            event={'microbiome_health'}
          />
        )
      )}
    </div>
  );
}
