import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';
import { Alert, MenuItem, Select } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdminUserTable from './admin-user-table';

export default function AdminUserReport() {
  const [parent, setParent] = useState('');
  const [stage, setStage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState(null);
  const [searching, setSearching] = useState(false);

  const handleStartDateChange = (date) => {
    if (!endDate || date < endDate) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (!startDate || date > startDate) {
      setEndDate(date);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setUsers(null);
    setSearching(true);

    const date = Date.parse(startDate);
    const date2 = new Date(endDate);
    date2.setHours(23);
    date2.setMinutes(59);
    date2.setSeconds(59);
    const isBoth = parent === 'both';
    const isParent = isBoth ? undefined : parent === 'true';

    const config = {
      url: getUrl() + '/report',
      method: 'post',
      data: {
        ...(isParent !== undefined && { isParent }),
        startDate: date,
        endDate: date2.getTime(),
        stage: stage === 'all' ? undefined : Number(stage),
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUsers(response.data.data.Items);
        setSearching(false);
      })
      .catch(() => {
        setSearching(false);
        setUsers(null);
        setMessageType('error');
        setErrorMessage(
          'There has been a problem with the request, please try again later'
        );
      });
  };

  return (
    <>
      <Box component="form" display="grid" noValidate onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: 'white',
            padding: '50px 30px 30px 30px',
            borderRadius: '10px',
          }}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={endDate}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Is Parent
              </Typography>
              <Select
                value={parent}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={'true'}
                onChange={(event) => setParent(event.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Stage
              </Typography>
              <Select
                value={stage}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={'true'}
                onChange={(event) => setStage(event.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          fullWidth
          disabled={searching}
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Find Users
        </Button>
        <AdminUserTable users={users} />
        <div style={{ marginTop: '10px', maxWidth: '400px' }}>
          {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
        </div>
      </Box>
      {searching && <CircularProgress />}
    </>
  );
}
