import * as React from 'react';
import questions from './questions.json';
import QuestionTemplate from './QuestionTemplate';

export default function Bacterial_Viral() {
  return (
    <div className="question-container">
      {Object.entries(questions['bacterial_/_viral']).map(
        ([key, value], index) => (
          <QuestionTemplate
            key={key}
            identifier={key}
            type={value.type}
            label={value.label}
            event={'bacterial_/_viral'}
          />
        )
      )}
    </div>
  );
}
