import * as React from 'react';
import questions from './questions.json';
import QuestionTemplate from './QuestionTemplate';

export default function Cavitation_HiddenInfection() {
  return (
    <div className="question-container">
      {Object.entries(questions['cavitation_/_hidden_infection']).map(
        ([key, value], index) => (
          <QuestionTemplate
            key={key}
            identifier={key}
            type={value.type}
            label={value.label}
            event={'cavitation_/_hidden_infection'}
          />
        )
      )}
    </div>
  );
}
