import * as React from 'react';
import questions from './questions.json';
import QuestionTemplate from './QuestionTemplate';

export default function Sibo() {
  return (
    <div className="question-container">
      {Object.entries(questions.sibo).map(([key, value], index) => (
        <QuestionTemplate
          key={key}
          identifier={key}
          type={value.type}
          label={value.label}
          event={'sibo'}
        />
      ))}
    </div>
  );
}
