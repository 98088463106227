import questions_template from './questions_template.json';
import questions_template_copy from './questions_template_copy.json';
import { getUserLeadScoreId, getUserNTQ } from './AuthService';
import { createConfigRequest, getApiKey, getUrlBasedOnEnv } from '../api/agent';
import axios from 'axios';
import questionsJson from '../../components/new-form/steps/questions.json';

export const loadQuestionsToSessionStorage = async () => {
  if (getUserNTQ()) {
    const axiosRequest = createConfigRequest('get-ntq', 'post', {
      ntq_id: getUserNTQ(),
      lead_score_id: getUserLeadScoreId(),
    });
    try {
      const { data } = await axios(axiosRequest);
      const modifiedData = modifyValues(data.result);
      sessionStorage.setItem('questions', JSON.stringify(modifiedData));
    } catch (error) {
      throw new Error('Error loading questions');
    }
  } else {
    sessionStorage.setItem(
      'questions',
      JSON.stringify(questions_template_copy)
    );
  }
};

const previousQuestionIdBaseOnId = (id) => {
  for (const parentKey in questionsJson) {
    const parentData = questionsJson[parentKey];
    const keys = Object.keys(parentData);
    const index = keys.indexOf(id);
    if (index > 0) {
      return keys[index - 1];
    }
  }
  return null;
};

export const validateQuestionsBySection = (section) => {
  const questionsBase = questionsJson[section];
  const answeredQuestions = getQuestions();

  for (const key in questionsBase) {
    if (questionsBase.hasOwnProperty(key)) {
      if (questionsBase[key].type === 'multi_text') {
        const previousQuestion = previousQuestionIdBaseOnId(key);
        const previousQuestionValue = answeredQuestions[previousQuestion];

        if (previousQuestionValue) {
          if (
            answeredQuestions[key] === null ||
            answeredQuestions[key] === ''
          ) {
            return false;
          }
        }
      } else {
        if (answeredQuestions[key] === null || answeredQuestions[key] === '') {
          return false;
        }
      }
    }
  }
  return true;
};

export const getMatchedSum = () => {
  const compareData = getQuestions();
  const questionsJsonData = questionsJson;

  let sum = 0;
  for (let key in questionsJsonData.questionaire) {
    if (
      questionsJsonData.questionaire.hasOwnProperty(key) &&
      compareData.hasOwnProperty(key)
    ) {
      sum += compareData[key];
    }
  }
  return sum;
};

export const loadQuestionsSessionStorageFromJsonOrEndpoint = async () => {
  if (getUserNTQ()) {
    const data = {
      ntq_id: getUserNTQ(),
      lead_score_id: getUserLeadScoreId(),
    };
    const config = {
      url: getUrlBasedOnEnv() + '/get-ntq',
      method: 'post',
      data,
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios(config);
      sessionStorage.setItem(
        'questions',
        JSON.stringify(modifyValues(response.data.result))
      );
      return true;
    } catch (error) {
      if (sessionStorage.getItem('questions') === null) {
        sessionStorage.setItem('questions', JSON.stringify(questions_template));
      }
      return true;
    }
  } else {
    if (sessionStorage.getItem('questions') === null) {
      sessionStorage.setItem('questions', JSON.stringify(questions_template));
      return true;
    }
    return true;
  }
};

function modifyValues(obj) {
  for (let key in obj) {
    if (['0', '1', '2', '3', '4', '5', '6'].includes(obj[key])) {
      obj[key] = Number(obj[key]);
    }
    if (['true'].includes(obj[key])) {
      obj[key] = true;
    }
    if (['false'].includes(obj[key])) {
      obj[key] = false;
    }
    if (obj[key] === null) {
      obj[key] = null;
    }
  }
  return obj;
}
export const getQuestion = (key) => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  if (questions === 'undefined' || !questions) {
    return null;
  } else {
    return questions[key];
  }
};

export const setQuestion = (key, value) => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  questions[key] = value;
  sessionStorage.setItem('questions', JSON.stringify(questions));
};

export const setCompletedNTQFlag = () => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  questions.ntq_completed = true;
  sessionStorage.setItem('questions', JSON.stringify(questions));
};

export const getQuestions = () => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  if (questions === 'undefined' || !questions) {
    return null;
  } else {
    return questions;
  }
};

export const getNeuroToxicityScore = () => {
  const questions = questionsJson.questionaire;
  const questions_results = getQuestions();

  if (questions_results === 'undefined' || !questions_results) {
    return;
  }

  let sum = 0;
  Object.keys(questions).forEach((key) => {
    if (
      questions_results.hasOwnProperty(key) &&
      questions_results[key] !== null
    ) {
      sum += questions_results[key];
    }
  });

  return sum;
};

export const setNtqPercentage = (percentage) => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  questions.ntq_percentage = percentage;
  sessionStorage.setItem('questions', JSON.stringify(questions));
};

export const getNtqPercentage = () => {
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  if (questions === 'undefined' || !questions) {
    return null;
  } else {
    return questions.ntq_percentage;
  }
};

export const compareQuestions = (
  answeredQuestions,
  visibleQuestions,
  ignoreLastQuestion = true
) => {
  for (let i = 0; i < visibleQuestions.length; i++) {
    // eslint-disable-next-line no-unused-vars
    let [key, value] = visibleQuestions[i];
    if (
      answeredQuestions[key] === null ||
      answeredQuestions[key] === '' ||
      answeredQuestions[key] === undefined
    ) {
      if (!(key === 'cold_extremities' && !ignoreLastQuestion)) {
        return false;
      }
    }
  }
  return true;
};

export const validateQuestionnaireSection = (ignoreLastQuestion = true) => {
  const answeredQuestions = getQuestions();
  const visibleQuestions = getVisibleQuestions();
  for (let i = 0; i < visibleQuestions.length; i++) {
    // eslint-disable-next-line no-unused-vars
    let [key, value] = visibleQuestions[i];
    if (
      answeredQuestions[key] === null ||
      answeredQuestions[key] === '' ||
      answeredQuestions[key] === undefined
    ) {
      if (!(key === 'cold_extremities' && !ignoreLastQuestion)) {
        return false;
      }
    }
  }
  return true;
};

export const getVisibleQuestions = () => {
  const visibleQuestionsString = sessionStorage.getItem('visibleQuestions');
  if (visibleQuestionsString) {
    return JSON.parse(visibleQuestionsString);
  }
  return [];
};
