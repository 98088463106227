import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuDrawer from './MenuDrawer';
import PropTypes from 'prop-types';
import { getUser } from '../services/AuthService';
import MenuNavbar from '../../components/navBar/navBar';

export default function StepsLayout({ children }) {
  const [user] = React.useState(getUser());
  return (
    <Box sx={{ width: '100%' }}>
      {user != null && <MenuNavbar />}
      <Box component="main" sx={{ width: '100%' }}>
        <Container sx={{ maxWidth: '100% !important' }}>
          <Box
            className="steps_layout"
            component="main"
            id="steps-layout-container"
            sx={{ padding: '15px 35px 50px 35px' }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

StepsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
};
