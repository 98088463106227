import * as React from 'react';
import ReusableQuestionare from '../../../app/common/ReusableQuestionare';
import questions from './questions.json';
import Typography from '@mui/material/Typography';
import { MenuItem, Select } from '@mui/material';
import {
  getQuestion,
  setQuestion,
} from '../../../app/services/QuestionsService';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';

const Questionare = React.forwardRef((props, ref) => {
  const questionEntries = Object.entries(questions.questionaire);
  const [currentStartIndex, setCurrentStartIndex] = React.useState(0);
  const [howMuch, setHowMuch] = React.useState(
    getQuestion(
      'how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_'
    )
      ? getQuestion(
          'how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_'
        )
      : '---'
  );
  const lastIndex = questionEntries.length - 1;
  const questionsPerPage = 10;
  // eslint-disable-next-line no-unused-vars
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [isFirstPage, setIsFirstPage] = React.useState(true);
  const [error, setError] = React.useState(false);

  const setQuestionValue = (value) => {
    setHowMuch(value);
    setQuestion(
      'how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_',
      value
    );
  };

  const updateVisibleQuestions = (startIndex, endIndex) => {
    const visibleQuestions = questionEntries.slice(startIndex, endIndex);
    sessionStorage.setItem(
      'visibleQuestions',
      JSON.stringify(visibleQuestions)
    );
  };

  React.useEffect(() => {
    const newIsLastPage =
      currentStartIndex + questionsPerPage >= questionEntries.length;
    setIsLastPage(newIsLastPage);
    props.onIsLastPageChange(newIsLastPage);
  }, [currentStartIndex, questionEntries.length, questionsPerPage, props]);

  React.useEffect(() => {
    setIsFirstPage(currentStartIndex === 0);
  }, [currentStartIndex]);

  // New useEffect for updateVisibleQuestions
  React.useEffect(() => {
    updateVisibleQuestions(
      currentStartIndex,
      currentStartIndex + questionsPerPage
    );
    // eslint-disable-next-line
  }, [currentStartIndex, questionsPerPage]);

  function handleQuestionEvent() {
    const questionValue = getQuestion(
      'how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_'
    );
    if (
      questionValue === null ||
      questionValue === '' ||
      questionValue === '---'
    ) {
      setError(true);
    }
  }

  useEffect(() => {
    window.addEventListener('questionHowMuchWouldYou', handleQuestionEvent);
    return () => {
      window.removeEventListener(
        'questionHowMuchWouldYou',
        handleQuestionEvent
      );
    };
  }, []);

  const showNextQuestions = () => {
    if (currentStartIndex + questionsPerPage < questionEntries.length) {
      setCurrentStartIndex(currentStartIndex + questionsPerPage);
    }
  };

  React.useImperativeHandle(ref, () => ({
    showNextQuestions,
    showPreviousQuestions,
  }));

  const showPreviousQuestions = () => {
    if (currentStartIndex >= questionsPerPage) {
      setCurrentStartIndex(currentStartIndex - questionsPerPage);
    }
  };

  const visibleQuestions = questionEntries.slice(
    currentStartIndex,
    currentStartIndex + questionsPerPage
  );

  return (
    <div>
      <Typography
        className={`quest_title ${!isFirstPage ? 'hide-text' : ''}`}
        component="p"
        sx={{ fontSize: '18px', padding: '50px 7% 0 7%' }}
      >
        Welcome to our questionnaire designed to help you assess your symptoms.
        This questionnaire consists of 64 questions, and you will need to scroll
        down to answer all of them. For each question, please rate the frequency
        and severity of your symptoms on a scale of 0 to 4.
        <br />
        <br />
        The questions cover a range of symptoms that may be related to various
        health conditions. By answering these questions, you will provide us
        with valuable information that can help us understand your symptoms
        better and provide you with more personalized care.
      </Typography>
      <div
        className="question-container"
        id="general-question-container"
        style={{ paddingTop: '10px' }}
      >
        {visibleQuestions.map(([key, value], index) => (
          <React.Fragment key={key}>
            <ReusableQuestionare
              key={key}
              identifier={key}
              value={value.value}
              label={value.label}
              isLast={currentStartIndex + index === lastIndex}
              setErrorMessage={props.setErrorMessage}
              setMessageType={props.setMessageType}
            />
            {currentStartIndex + index === lastIndex && (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                display="flex"
                flexDirection="column"
              >
                <Typography
                  variant="p"
                  className="title-questions"
                  sx={{ fontSize: '14px', fontWeight: 'bolder' }}
                >
                  How much would you estimate you’ve spent on your health trying
                  to find answers?"
                </Typography>
                <Select
                  sx={{ width: '40%', height: '50px', marginTop: '20px' }}
                  value={howMuch}
                  labelId="how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_"
                  id="how_much_would_you_estimate_you_ve_spent_on_your_health_trying_to_find_answers_"
                  error={
                    (howMuch.length === 0 && error) ||
                    (howMuch === '---' && error)
                  }
                  onChange={(event) => setQuestionValue(event.target.value)}
                >
                  <MenuItem value="---"></MenuItem>
                  <MenuItem value="Less than $1,000">Less than $1,000</MenuItem>
                  <MenuItem value="1-10k">1-10k</MenuItem>
                  <MenuItem value="10-30k">10-30k</MenuItem>
                  <MenuItem value="30-100k">30-100k</MenuItem>
                  <MenuItem value="100k+">100k+</MenuItem>
                </Select>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export default Questionare;
