import React, { useEffect } from 'react';
import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds';

const VideoEmbed = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = 'https://fast.wistia.com/embed/medias/ixntcuyb6i.jsonp';
    script1.async = true;

    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, []);

  return (
    <WistiaProvider>
      <WistiaPlayer
        hashedId="ixntcuyb6i"
        videoFoam={false}
        id="wistia_ixntcuyb6i"
        style={{
          height: '475px',
          width: '850px',
        }}
      />
    </WistiaProvider>
  );
};

export default VideoEmbed;
