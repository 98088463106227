import React, { useEffect, useState } from 'react';
import scriptjs from 'scriptjs';
import { getUserData } from '../../services/AuthService';

function DiabetesDouble() {
  const [user] = useState(getUserData());
  const generateDataSoQryPrm = () => {
    let parameters = `email=${user.email}`;
    if (user.firstName) {
      parameters += `&name=${user.firstName}`;
    }
    if (user.lastName) {
      parameters += `&last name=${user.lastName}`;
    }
    return parameters;
  };
  useEffect(() => {
    scriptjs('https://cdn.oncehub.com/mergedjs/so.js');
  }, []);

  return (
    <div
      style={{ width: '100%', height: '548px', maxWidth: '900px' }}
      id="SOIDIV_DoubleDiabetesCellularHealthAnalysis"
      data-so-page="DoubleDiabetesCellularHealthAnalysis"
      data-height="550"
      data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
      data-so-qry-prm={generateDataSoQryPrm()}
      data-psz="00"
    ></div>
  );
}

export default DiabetesDouble;
