import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const useFetchSamcartCustomer = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const decodedOrderId = decodeURIComponent(orderId);
      const decodedEmail = searchParams.get('email')
        ? decodeURIComponent(searchParams.get('email'))
        : '';

      if (decodedOrderId) {
        try {
          const response = await axios.get(
            `https://api.pompaprogram.com/get-samcart-customer?order_id=${decodedOrderId}`
          );
          const { first_name, last_name, email, phone } =
            response.data.customer;
          setCustomerData({
            firstName: first_name,
            lastName: last_name,
            email,
            phone,
          });
        } catch (error) {
          console.error('Error fetching customer data:', error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [orderId, searchParams]);

  return { customerData, loading };
};
