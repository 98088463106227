import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from '../../app/common/Logo';
import { useState } from 'react';
import axios from 'axios';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import DarkLogo from '../../app/common/DarkLogo';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');

  const submitHandler = (event) => {
    event.preventDefault();
    if (email.trim() === '') {
      setErrorMessage('Email is required.');
      return;
    }
    setErrorMessage(null);
    setAuthenticating(true);
    const config = {
      url: getUrl() + '/reset',
      method: 'post',
      data: {
        email: email,
        action: 'request',
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(() => {
        setMessageType('success');
        setAuthenticating(false);
        setErrorMessage('A reset link has been sent to your email address.');
      })
      .catch((error) => {
        setMessageType('error');
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  const setEmailForm = (value) => {
    setEmail(value.toLowerCase());
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DarkLogo />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            value={email}
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            onChange={(event) => setEmailForm(event.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/register-secondary" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
