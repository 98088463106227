import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import WatchVideo from '../VideoPlayer/WatchVideo';

const stepsArray = [
  'Fill the urine cup: Start by filling the provided urine cup with your urine sample.',
  'Use a pipette: Transfer the urine into a vial using a pipette. The vial contains a reactive agent.',
  'Wait for two minutes: Allow the reactive agent to work for two minutes.',
  'Compare colors: After the wait, compare the color of the urine in the vial to a provided chart. This step is crucial for interpreting the results accurately.',
  'First morning urine and midstream: For the best results, use your first morning urine and take a midstream sample.',
  'Timing matters: Avoid exercise on the day of the test, no alcohol for 24 hours prior, and no antioxidant supplements for 24 hours. However, you have the option to continue taking antioxidants if you want to see how they affect your test results.',
  'Avoid anti-inflammatory medications: Do not use anti-inflammatory medications before the test, as they can interfere with the results.',
];

export const HowToTakeInfo = () => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: '0px', md: '10px 20px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '60% 40%' },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '0px', md: '30px' },
        }}
      >
        <WatchVideo
          videoId="hhevd9xdkl"
          videoStyle={{
            width: '100%',
            height: '500px',
            borderRadius: '10px',
          }}
        />
      </Box>
      <Box style={{ marginLeft: '20px', overflow: 'auto', height: '550px' }}>
        {stepsArray.map((step, index) => (
          <React.Fragment key={index}>
            <Typography
              variant="body1"
              gutterBottom
              color="text.secondary"
              sx={{ color: 'black', fontSize: '18px' }}
            >
              {index + 1}. {step}
            </Typography>
            <Box sx={{ margin: 3 }} />
          </React.Fragment>
        ))}
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          In summary, following these simple steps and tips will ensure that you
          get the most accurate results from your urine test. It’s an easy
          process that can be done at any time during the day, with first
          morning urine and midstream being the preferred choices for optimal
          accuracy.
        </Typography>
      </Box>
    </Box>
  );
};
