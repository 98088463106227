import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import { getApiKey, getUrl } from '../../app/api/agent';
import axios from 'axios';
import {
  getProduct,
  getUserSessionEmail,
  isUserParent,
  setStage,
} from '../../app/services/AuthService';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import StepsLayout from '../../app/common/StepsLayout';
import './WatchVideo.css';
import { ConsultInfo } from './consultInfo';
import { ScienceInfo } from './scienceInfo';
import { Faq } from './faq';
import { HowToTakeInfo } from './howToTakeInfo';

const TABS_INFO = [
  {
    tabName: 'Consult',
    content: ConsultInfo,
  },
  {
    tabName: 'The Science',
    content: ScienceInfo,
  },
  {
    tabName: 'Frequently Asked Questions',
    content: Faq,
  },
  {
    tabName: 'How To Take',
    content: HowToTakeInfo,
  },
];

const WatchVideo = () => {
  const [value, setValue] = React.useState('1');
  const [lastVideo, setLastVideo] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [settingStage, setSettingStage] = useState(false);
  const [isParent, setIsParent] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsParent(isUserParent());
  }, []);

  const nextVideo = useCallback(() => {
    const newValue = `${parseInt(value) + 1}`;
    setValue(newValue);
    setLastVideo(newValue === `${TABS_INFO.length}`);
  }, [value]);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    setLastVideo(newValue === `${TABS_INFO.length}`);
  }, []);

  const onSubmit = () => {
    let stage = 0;
    if (getProduct() === 'single' || getProduct() === 'double') {
      stage = 6;
    } else {
      stage = 5;
    }
    const config = {
      url: getUrl() + '/stage',
      method: 'post',
      data: {
        email: getUserSessionEmail(),
        stage: stage,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    setSettingStage(true);
    axios(config)
      .then(() => {
        setSettingStage(false);
        setStage(5);
        if (getProduct() === 'single' || getProduct() === 'double') {
          navigate('/');
        } else {
          if (getProduct()) {
            if (getProduct().toLowerCase()) {
              navigate('/child-profile');
            } else {
              navigate('/');
            }
          } else {
            navigate('/');
          }
        }
      })
      .catch((error) => {
        setSettingStage(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };
  return (
    <StepsLayout>
      <Box display="flex">
        {getProduct() === 'double' ? (
          <img src="/4-icon.png" alt="Logo" style={{ height: '84px' }} />
        ) : (
          <>
            {isParent && (
              <img
                className="icon-header"
                src="/4-icon.png"
                alt="Logo"
                style={{ height: '84px' }}
              />
            )}
            {!isParent && (
              <img
                className="icon-header"
                src="/3-icon.png"
                alt="Logo"
                style={{ height: '84px' }}
              />
            )}
          </>
        )}
        <Typography component="h1" variant="h3" className="steps-header">
          Consult Requirements
        </Typography>
      </Box>
      <div
        id="watch-video-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ marginTop: '20px', display: 'flex' }}>
              <TabList
                sx={{
                  margin: 'auto',
                  display: 'inline-flex',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                }}
                onChange={handleChange}
                allowScrollButtonsMobile
                variant="scrollable"
                aria-label="lab API tabs example"
              >
                {TABS_INFO.map((tabInfo, index) => {
                  return (
                    <Tab
                      key={index}
                      sx={{
                        background:
                          value === `${index + 1}` ? '#60a5fa' : 'white',
                        color:
                          value === `${index + 1}`
                            ? 'white !important'
                            : 'black',
                        borderRadius: value === `${index + 1}` ? '10px' : '0px',
                        fontSize: '17px',
                        textTransform: 'initial !important',
                      }}
                      label={tabInfo.tabName}
                      value={`${index + 1}`}
                      defaultValue
                    />
                  );
                })}
              </TabList>
            </Box>
            {TABS_INFO.map((tabInfo, index) => {
              const TabContent = tabInfo.content;
              return (
                <TabPanel key={index} value={`${index + 1}`}>
                  <TabContent />
                </TabPanel>
              );
            })}
          </TabContext>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              paddingRight: { xs: '0', md: '20px' },
            }}
          >
            <Button
              sx={{
                mt: 3,
                mb: 2,
                width: '270px',
                borderRadius: '10px',
                backgroundColor: '#5fa5fa',
                fontSize: '17px',
              }}
              disabled={settingStage}
              onClick={lastVideo ? onSubmit : nextVideo}
              type="submit"
              variant="contained"
            >
              {!lastVideo && <span>Watch Next Video</span>}
              {lastVideo && <span>FINISH</span>}
            </Button>
            <div>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </div>
          </Box>
        </Box>
      </div>
    </StepsLayout>
  );
};

export default WatchVideo;
