import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import {
  setUserData,
  setUserSessionEmail,
} from '../../app/services/AuthService';
import DarkLogo from '../../app/common/DarkLogo';

export default function SetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageType, setMessageType] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(searchParams.get('email'));
    setToken(searchParams.get('token'));
    // eslint-disable-next-line
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    if (password.trim() === '') {
      setErrorMessage('Password cannot be empty');
      return;
    }
    setErrorMessage(null);
    setAuthenticating(true);
    const config = {
      url: getUrl() + '/reset',
      method: 'post',
      data: {
        email: email,
        action: 'confirm',
        token: token,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(() => {
        setMessageType('success');
        setAuthenticating(false);
        setErrorMessage(
          'Password has been updated you will be automatically logged in.'
        );
        setTimeout(() => {
          login();
        }, 3000);
      })
      .catch((error) => {
        setMessageType('error');
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  function login() {
    const loginconfig = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(loginconfig)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        navigate('/');
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DarkLogo />
        <Typography component="h1" variant="h5">
          Set New Password
        </Typography>
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            value={password}
            fullWidth
            id="password"
            label="New Password"
            name="password"
            type="password"
            autoComplete="password"
            onChange={(event) => setPassword(event.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Set New Password
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
