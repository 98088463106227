import React from 'react';
import StepsLayout from '../../app/common/StepsLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdminUserReport from './admin-user-report';
import AdminUserHistory from './admin-user-history';

export default function AdminUserIndex() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StepsLayout>
      <Box display="flex">
        <Typography component="h1" variant="h3" className="steps-header">
          User Report
        </Typography>
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Date Filter Report" value="1" />
              <Tab label="History Filter Report" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <AdminUserReport />
          </TabPanel>
          <TabPanel value="2">
            <AdminUserHistory />
          </TabPanel>
        </TabContext>
      </Box>
    </StepsLayout>
  );
}
