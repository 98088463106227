import React from 'react';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GenericScheduleEmbed } from '../../app/common/Embed/GenericScheduleEmbed';
import { useSearchParams } from 'react-router-dom';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';

export const Time2BookReferral = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('Name');
  const lastName = searchParams.get(decodeURIComponent('lastName'));
  const mobile = searchParams.get('mobile');
  const email = searchParams.get('email');
  const decodedName = decodeURIComponent(name);
  const decodedLastName = decodeURIComponent(lastName);
  const decodedMobile = decodeURIComponent(mobile);
  const decodedEmail = decodeURIComponent(email);

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="85%" message="Almost Complete..." />
        </Typography>
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          maxWidth: '900px',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '40px',
          paddingTop: '20px',
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 2,
            mt: 2,
            '@media (max-width: 700px)': {
              fontSize: '22px',
            },
          }}
        >
          Book your appointment for an available time below.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
            mb: 4,
            mt: 0,
            '@media (max-width: 700px)': {
              fontSize: '22px',
              mb: 2,
            },
          }}
        >
          If there are no times that work for you, please book an appointment
          and you will be able to reschedule when we reach out to you via text.
        </Typography>
        <div
          style={{
            width: '80%',
            height: '548px',
            maxWidth: '900px',
            display: 'block',
          }}
        >
          <GenericScheduleEmbed
            iframePage={'PompaProgramConsultation-R'}
            iframeId={'SOIDIV_PompaProgramConsultation-R'}
            email={decodedEmail}
            name={decodedName}
            lastName={decodedLastName}
            phone={decodedMobile}
          />
        </div>
        <Box mb={2} />
      </div>
    </BookStepsLayout>
  );
};
