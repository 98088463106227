import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Loader from '../loader/Loader';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import { renderToString } from 'react-dom/server';

export default function CreateCloseDoor() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [productName, setProductName] = useState('');
  // eslint-disable-next-line
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setEmail(searchParams.get('email').toLowerCase());
    setFirstName(searchParams.get('fname'));
    setLastName(searchParams.get('lname'));
    setProductName(searchParams.get('product'));
    if (email && productName && !password) {
      autoregister();
    }
    if (password) {
      login();
    }
  }, [email, password]);

  const sendEmail = (token) => {
    const emailContent = renderToString(htmlTemplate(token));
    const config = {
      url: 'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod/email',
      method: 'post',
      data: {
        email: email,
        content: emailContent,
        subject: 'congratulations! your account is ready',
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const htmlTemplate = (token) => {
    return (
      <html>
        <head>
          <title>Welcome to the Pompa Group - Account Creation Success!</title>
        </head>
        <body>
          <p>Dear {firstName},</p>
          <p>
            We are delighted to inform you that your account with the Pompa
            Group has been successfully created. To complete the setup process
            and access your account, please click the link below to set your
            password:
          </p>
          <p>
            <a
              href={`https://apply.pompaprogram.com/reset?token=${token}&email=${email}`}
            >
              https://apply.pompaprogram.com/reset
            </a>
          </p>
          <p>
            We look forward to having you as a member of our community and thank
            you for choosing the Pompa Group.
          </p>
          <p>Best,</p>
          <p>The Pompa Group Team</p>
        </body>
      </html>
    );
  };

  const autoregister = () => {
    const config = {
      url: getUrl() + '/autoregister-closedoor',
      method: 'post',
      data: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        productName: productName,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
        setPassword(response.data.password);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };
  const login = () => {
    const config = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        sendEmail(response.data.token);
        setTimeout(() => {
          navigate('/book-call');
        }, 3000);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5">
          Creating your account
        </Typography>
        <Typography component="h2" variant="h5">
          please stand by...
        </Typography>
        <Loader />
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
