import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function BookStepsLayout({ children }) {
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box component="main" sx={{ backgroundColor: '#ffffff', width: '100%' }}>
        {children}
      </Box>
    </Box>
  );
}

BookStepsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
};
