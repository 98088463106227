/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const ChildProfileForm = (props) => {
  const { firstName, lastName, email, updateChild } = props;
  function handleFirstNameChange(newFirstName) {
    updateChild({ firstName: newFirstName, lastName, email });
  }

  function handleLastNameChange(newLastName) {
    updateChild({ firstName, lastName: newLastName, email });
  }

  function handleEmailChange(newEmail) {
    updateChild({ firstName, lastName, email: newEmail });
  }

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '50px 30px 30px 30px',
        borderRadius: '10px',
      }}
      sx={{ mt: 3 }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="p" className="input-label">
            First Name
          </Typography>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            autoFocus
            onChange={(event) => handleFirstNameChange(event.target.value)}
            value={firstName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="p" className="input-label">
            Last Name
          </Typography>
          <TextField
            required
            fullWidth
            id="lastName"
            name="lastName"
            autoComplete="family-name"
            onChange={(event) => handleLastNameChange(event.target.value)}
            value={lastName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="p" className="input-label">
            Email{' '}
            <Typography sx={{ fontWeight: 'regular' }}>
              (Please don't use the email you used to purchase the product)
            </Typography>
          </Typography>
          <TextField
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            onChange={(event) => handleEmailChange(event.target.value)}
            value={email}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChildProfileForm;
