import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import WatchVideo from '../VideoPlayer/WatchVideo';

const pointsArray = [
  {
    title: 'Understanding the Cellular Inflammation Test',
    content:
      'At its core, the Cellular Inflammation Test is deceptively simple: it measures a specific substance called Malondialdehyde (MDA) found in urine. But don’t let its simplicity fool you; this test is a powerful window into the state of your cellular health.',
  },
  {
    title: 'The Role of MDA',
    content:
      'MDA is not just any substance—it’s a byproduct generated when cell membranes and lipids experience stress. When these lipids undergo oxidation, they transform into MDA. This molecule is considered a potential threat to the body, capable of causing issues with DNA and proteins, among other concerns.',
  },
  {
    title: 'Quantitative Insights',
    content:
      'Despite its straightforward approach, the Cellular Inflammation Test offers invaluable quantitative insights. A simple color change in the urine provides a measurable indicator of cellular inflammation. This quantitative aspect makes it a reliable tool for assessing your health.',
  },
  {
    title: 'Divergence from Blood Tests',
    content:
      'It’s important to distinguish this test from conventional blood tests, which generally assess systemic inflammation. The Cellular Inflammation Test, in contrast, hones in on the health and resilience of cell membranes. This nuanced approach provides unique insights into your cellular health.',
  },
  {
    title: 'Factors Impacting Test Results',
    content:
      'Several factors can influence your test results. These include exercise, alcohol consumption, and certain medications. To maintain the accuracy of your test, adhering to specific guidelines is crucial. This will ensure that the results reflect your true cellular health.',
  },
  {
    title: 'Chronic vs. Short-Term Inflammation',
    content:
      'Understanding the difference between chronic inflammation (indicative of ongoing health issues) and short-term inflammation (often caused by factors like exercise) is essential. Accurate interpretation of your test results depends on recognizing this distinction.',
  },
  {
    title: 'Environmental Considerations',
    content:
      'Intriguingly, if multiple family members consistently receive high test results, it may signal an environmental stressor at play. Factors such as mold or high radiation (EMFs) within the household could be affecting everyone’s cellular health. This is a crucial point to discuss with your coach or healthcare provider.',
  },
  {
    title: 'Taking the Test: Step by Step',
    content:
      'To conclude our exploration of the Cellular Inflammation Test, we provide comprehensive instructions on how to take the test correctly. Following these guidelines ensures accuracy and reliable results, allowing you to make informed decisions about your health and well-being.',
  },
];

export const ScienceInfo = () => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: '0px', md: '10px 20px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '60% 40%' },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '0px', md: '30px' },
        }}
      >
        <WatchVideo
          videoId="83oknpnn1w"
          videoStyle={{
            width: '100%',
            height: '500px',
            borderRadius: '10px',
          }}
        />
      </Box>
      <Box style={{ marginLeft: '20px', overflow: 'auto', height: '550px' }}>
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          Welcome to the in-depth exploration of the Cellular Inflammation Test,
          where we break down the scientific foundations of this remarkable
          diagnostic tool. In this section, we’ll draw from the expertise of Dr.
          Pompa and Dr. Shane, who bring years of experience and insights into
          the world of cellular inflammation testing.
        </Typography>
        <Box sx={{ margin: 3 }} />
        {pointsArray.map((point, index) => (
          <React.Fragment key={index}>
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ marginTop: '10px', fontWeight: 'bold' }}
            >
              {point.title}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="text.secondary"
              sx={{ color: 'black', fontSize: '18px' }}
            >
              {point.content}
            </Typography>
          </React.Fragment>
        ))}
        <Box sx={{ margin: 3 }} />
        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          sx={{ color: 'black', fontSize: '18px' }}
        >
          By delving into the science behind this test, we aim to empower you
          with knowledge and understanding. Armed with this information, you can
          harness the full potential of the Cellular Inflammation Test to
          monitor and optimize your cellular health.
        </Typography>
      </Box>
    </Box>
  );
};
